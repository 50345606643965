import React from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Cookies from "js-cookie";
import { decrypt } from "../utils/utility";

const ProtectedRoute = ({ element, requiredPermissions = [] }) => {
  const isAuthenticated = useAuth();

  if (isAuthenticated === null) {
    // Loading state or skeleton can be returned here
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // return isAuthenticated ? element : <Navigate to="/login" />;

  const userPermissions = Cookies.get("permissions")
    ? JSON.parse(decrypt(Cookies.get("permissions")))
    : [];

  const hasPermission = requiredPermissions.every((permission) =>
    userPermissions.includes(permission)
  );

  if (!hasPermission) {
    return <Navigate to="/dashboard" />;
  }

  return element;
};

export default ProtectedRoute;
