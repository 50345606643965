import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles/core-new.css";
import "./styles/theme-default.css";
import "./styles/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css"; // Import Perfect Scrollbar CSS
import { ApolloProvider } from "@apollo/client";
import client from "./apollo/client";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);
