// src/graphql/userQueries.js
import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query Users($limit: Int!, $offset: Int!) {
    users(limit: $limit, offset: $offset) {
      id
      username
      email
      createdAt
      status
      role {
        name
      }
    }
  }
`;

export const GET_USERS_COUNT = gql`
  query UsersCount {
    usersCount
  }
`;

export const GET_USER = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      username
      email
      createdAt
      status
      role {
        name
      }
    }
  }
`;
