// src/graphql/userMutations.js
import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation LoginUser($email: String!, $password: String!) {
    loginUser(email: $email, password: $password) {
      token
      id
      username
      email
      status
      role {
        id
        name
        permissions {
          id
          name
          description
        }
      }
    }
  }
`;

export const REGISTER_USER = gql`
  mutation RegisterUser(
    $username: String!
    $email: String!
    $password: String!
    $roleName: String!
  ) {
    registerUser(
      username: $username
      email: $email
      password: $password
      roleName: $roleName
    ) {
      id
      username
      email
      createdAt
      status
      role {
        id
        name
      }
    }
  }
`;

export const UPDATE_USER_STATUS = gql`
  mutation UpdateUser($id: ID!, $status: Int!) {
    updateUser(id: $id, status: $status) {
      status
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: ID!
    $username: String
    $password: String
    $status: Int
    $roleName: String
  ) {
    updateUser(
      id: $id
      username: $username
      password: $password
      status: $status
      roleName: $roleName
    ) {
      id
      username
      email
      createdAt
      status
      role {
        id
        name
        description
        permissions {
          id
          name
          description
        }
      }
    }
  }
`;
