// export default useAuth;
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode"; // Adjusted import
import { useMutation } from "@apollo/client";
import { REFRESH_TOKEN_MUTATION } from "../graphql/queries";

const useAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION);

  useEffect(() => {
    const checkToken = async () => {
      const token = Cookies.get("token");

      if (!token) {
        if (location.pathname !== "/login") {
          navigate("/login");
        }
        setIsAuthenticated(false);
      } else {
        try {
          const decoded = jwtDecode(token);
          const currentTime = Date.now() / 1000;

          if (decoded.exp < currentTime) {
            try {
              const { data } = await refreshToken({ variables: { token } });
              Cookies.set("token", data.refreshToken.token);
              setIsAuthenticated(true);
            } catch (err) {
              Cookies.remove("token");
              navigate("/login");
              setIsAuthenticated(false);
            }
          } else {
            setIsAuthenticated(true);
          }
        } catch (err) {
          Cookies.remove("token");
          navigate("/login");
          setIsAuthenticated(false);
        }
      }
    };

    checkToken();
  }, [navigate, location.pathname, refreshToken]);

  return isAuthenticated;
};

export default useAuth;
