import Dashboard from "../components/Dashboard";
import UsersList from "../components/User/UsersList";
// import CreateUser from "../components/User/CreateUser";
import UserDetail from "../components/User/UserDetail";
import Profile from "../components/Account/Profile";
import Permissions from "../components/Permissions";
import Roles from "../components/Roles";
import Articles from "../components/Articles/Articles";
import MyArticles from "../components/Articles/MyArticles";
import ArticlesCreate from "../components/Articles/ArticleCreate";
import Categories from "../components/Categories/Categories";

const routesWithLayout = [
  {
    path: "/dashboard",
    element: <Dashboard />,
    isProtected: true,
    requiredPermissions: ["dashboard:access"],
  },
  {
    path: "/users",
    element: <UsersList />,
    isProtected: true,
    requiredPermissions: ["user:create"],
  },
  // { path: "/user/create", element: <CreateUser />, isProtected: true },
  { path: "/user/:id", element: <UserDetail />, isProtected: true },
  { path: "/account/profile", element: <Profile />, isProtected: true },
  { path: "/access/permissions", element: <Permissions />, isProtected: true },
  { path: "/access/roles", element: <Roles />, isProtected: true },
  // { path: "/artile/create", element: <Roles />, isProtected: true },
  {
    path: "/articles",
    element: <Articles />,
    isProtected: true,
    requiredPermissions: ["articles:access"],
  },
  {
    path: "/article/create",
    element: <ArticlesCreate />,
    isProtected: true,
    requiredPermissions: ["articles:create"],
  },

  {
    path: "/my-articles",
    element: <MyArticles />,
    isProtected: true,
    requiredPermissions: ["articles:view_own_articles"],
  },
  {
    path: "/categories",
    element: <Categories />,
    isProtected: true,
    requiredPermissions: ["articles:view_own_articles"],
  },
];

export default routesWithLayout;
