import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

export const encrypt = (data) => {
  if (!SECRET_KEY) {
    throw new Error("Secret key is not defined");
  }
  try {
    return CryptoJS.AES.encrypt(data, SECRET_KEY).toString();
  } catch (error) {
    console.error("Encryption error:", error);
    throw error;
  }
};

export const decrypt = (data) => {
  if (!SECRET_KEY) {
    throw new Error("Secret key is not defined");
  }
  try {
    const bytes = CryptoJS.AES.decrypt(data, SECRET_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error("Decryption error:", error);
    throw error;
  }
};

export const getUserStatusNameFromID = (status) => {
  if (!status) {
    throw new Error("Unknown Request");
  }
  try {
    if (status === 0) {
      return "Inactive";
    } else if (status === 1) {
      return "Active";
    }
  } catch (error) {
    // console.error("Decryption error:", error);
    throw error;
  }
};
