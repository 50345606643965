import React, { useState, useRef, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../page-auth.css";
import { GET_ALL_ROLES, REGISTER_USER } from "../graphql/queries";

const validationSchema = Yup.object({
  username: Yup.string().required("Username is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  roleName: Yup.string().required("Role is required"),
});

const Register = () => {
  const {
    data: rolesData,
    loading: rolesLoading,
    error: rolesError,
  } = useQuery(GET_ALL_ROLES);

  const [registerUser] = useMutation(REGISTER_USER);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toastRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };

  const showToast = () => {
    const toast = new window.bootstrap.Toast(toastRef.current);
    toast.show();
  };

  if (rolesLoading) return <p>Loading...</p>;
  if (rolesError) return <p>Error: {rolesError.message}</p>;

  const roles = rolesData.roles.filter((role) => role.name !== "admin");

  return (
    <div className="container-xxl">
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner">
          <div className="card">
            <div className="card-body">
              <div className="app-brand justify-content-center">
                <Link to="/" className="app-brand-link gap-2">
                  <span className="app-brand-logo demo"></span>
                  <span className="app-brand-text demo text-body fw-bolder">
                    Scribblerspot
                  </span>
                </Link>
              </div>
              <h4 className="mb-2">Adventure starts here 🚀</h4>
              <p className="mb-4">Make your app management easy and fun!</p>
              <Formik
                initialValues={{
                  username: "",
                  email: "",
                  password: "",
                  confirmPassword: "",
                  roleName: "",
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  try {
                    const { data } = await registerUser({
                      variables: {
                        username: values.username,
                        email: values.email,
                        password: values.password,
                        roleName: values.roleName,
                      },
                    });

                    if (data.registerUser) {
                      console.log("User registered:", data.registerUser);
                      setSuccessMessage(
                        "User registered successfully. Please check your inbox and verify your account."
                      );
                      setErrorMessage(""); // Clear error message
                      resetForm();
                      showToast(); // Show toast message
                    } else {
                      throw new Error(
                        "Unexpected error occurred during registration"
                      );
                    }
                  } catch (error) {
                    const message =
                      error?.graphQLErrors?.[0]?.message ||
                      "Error registering user";
                    setErrorMessage(message);
                    setSuccessMessage(""); // Clear success message
                    console.error("Error registering user:", error);
                  }
                  setSubmitting(false);
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    {errorMessage && (
                      <div className="alert alert-danger" role="alert">
                        {errorMessage}
                      </div>
                    )}
                    {successMessage && (
                      <div className="alert alert-success" role="alert">
                        {successMessage}
                      </div>
                    )}
                    <div className="mb-3">
                      <label htmlFor="username" className="form-label">
                        Username
                      </label>
                      <Field
                        name="username"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                      />
                      <ErrorMessage
                        name="username"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <Field
                        name="email"
                        type="email"
                        className="form-control"
                        autoComplete="off"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="mb-3 form-password-toggle">
                      <label className="form-label" htmlFor="password">
                        Password
                      </label>
                      <div className="input-group input-group-merge">
                        <Field
                          name="password"
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          autoComplete="new-password"
                        />
                        <span
                          className="input-group-text cursor-pointer"
                          onClick={togglePasswordVisibility}
                        >
                          <i
                            className={
                              showPassword ? "bx bx-show" : "bx bx-hide"
                            }
                          ></i>
                        </span>
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="mb-3 form-password-toggle">
                      <label className="form-label" htmlFor="confirmPassword">
                        Confirm Password
                      </label>
                      <div className="input-group input-group-merge">
                        <Field
                          name="confirmPassword"
                          type={showConfirmPassword ? "text" : "password"}
                          className="form-control"
                          autoComplete="new-password"
                        />
                        <span
                          className="input-group-text cursor-pointer"
                          onClick={toggleConfirmPasswordVisibility}
                        >
                          <i
                            className={
                              showConfirmPassword ? "bx bx-show" : "bx bx-hide"
                            }
                          ></i>
                        </span>
                      </div>
                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="roleName">
                        Role
                      </label>
                      <Field
                        as="select"
                        name="roleName"
                        className="form-select"
                      >
                        <option value="">Select a role...</option>
                        {roles.map((role) => (
                          <option key={role.id} value={role.name}>
                            {role.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="roleName"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="mb-3">
                      <div className="form-check">
                        <Field
                          className="form-check-input"
                          type="checkbox"
                          id="terms-conditions"
                          name="terms"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="terms-conditions"
                        >
                          I agree to <Link to="/">privacy policy & terms</Link>
                        </label>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary d-grid w-100"
                      disabled={isSubmitting}
                    >
                      Sign up
                    </button>
                  </Form>
                )}
              </Formik>
              <p className="text-center">
                <span>Already have an account?</span>
                <Link to="/login" className="nav-item nav-link px-0 me-xl-4">
                  <span>Sign in instead</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Toast */}
      <div
        className="toast position-fixed bottom-0 end-0 p-3 bg-success text-white"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        ref={toastRef}
      >
        <div className="toast-header">
          <strong className="me-auto">Success</strong>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="toast"
            aria-label="Close"
          ></button>
        </div>
        <div className="toast-body">User registered successfully!</div>
      </div>
    </div>
  );
};

export default Register;
