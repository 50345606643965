import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import Breadcrumbs from "./Breadcrumbs";
import {
  GET_PERMISSIONS,
  GET_PERMISSIONS_COUNT,
  ADD_PERMISSION,
  UPDATE_PERMISSION,
  DELETE_PERMISSION,
} from "../graphql/queries";

const Permissions = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedPermission, setSelectedPermission] = useState(null);
  const [permissionsPerPage, setPermissionsPerPage] = useState(10);

  // const permissionsPerPage = 10;

  const { loading, error, data, refetch } = useQuery(GET_PERMISSIONS, {
    variables: {
      limit: permissionsPerPage,
      offset: (currentPage - 1) * permissionsPerPage,
    },
  });

  const { data: countData } = useQuery(GET_PERMISSIONS_COUNT);

  const [addPermission] = useMutation(ADD_PERMISSION, {
    onCompleted: () => {
      setSuccessMessage("Permission added successfully!");
      refetch();
      closeModal("addPermissionModal");
    },
    onError: (error) => {
      console.error("Error adding permission:", error);
    },
  });

  const [updatePermission] = useMutation(UPDATE_PERMISSION, {
    onCompleted: () => {
      setSuccessMessage("Permission updated successfully!");
      refetch();
      closeModal("editPermissionModal");
    },
    onError: (error) => {
      console.error("Error updating permission:", error);
    },
  });

  const [deletePermission] = useMutation(DELETE_PERMISSION, {
    onCompleted: () => {
      setSuccessMessage("Permission deleted successfully!");
      refetch();
      closeModal("deletePermissionModal");
    },
    onError: (error) => {
      console.error("Error deleting permission:", error);
    },
  });

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    if (currentPage * permissionsPerPage < totalPermissions) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleAddPermission = (e) => {
    e.preventDefault();
    const { modalPermissionName, modalPermissionDescription } =
      e.target.elements;
    addPermission({
      variables: {
        name: modalPermissionName.value,
        description: modalPermissionDescription.value,
      },
    });
  };

  const handleEditPermission = (e) => {
    e.preventDefault();
    const { editPermissionName, editPermissionDescription } = e.target.elements;
    updatePermission({
      variables: {
        id: selectedPermission.id,
        name: editPermissionName.value,
        description: editPermissionDescription.value,
      },
    });
  };

  const handleDeletePermission = () => {
    deletePermission({ variables: { id: selectedPermission.id } });
  };

  const closeModal = (modalId) => {
    const modal = window.bootstrap.Modal.getInstance(
      document.getElementById(modalId)
    );
    modal.hide();
    document.body.classList.remove("modal-open");
    const backdrop = document.querySelector(".modal-backdrop");
    if (backdrop) backdrop.remove();
  };

  const handlePermissionsPerPageChange = (event) => {
    // setPermissionsPerPage(parseInt(event.target.value, 10));

    const newPermissionsPerPage = parseInt(event.target.value, 10);
    setPermissionsPerPage(newPermissionsPerPage);
    setCurrentPage(1);
    refetch({
      limit: newPermissionsPerPage,
      offset: 0,
    });
  };

  const filteredPermissions = data
    ? data.permissions.filter((permission) =>
        permission.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  const totalPermissions = countData ? countData.permissionsCount : 0;

  return (
    <div className="content-wrapper">
      <div className="container-xxl flex-grow-1 container-p-y">
        <Breadcrumbs items={[{ name: "Permissions", url: "/permissions" }]} />
        <div className="row">
          <div className="col-lg-12 mb-4 order-0">
            <div className="card">
              <h5 className="card-header">Permissions</h5>
              {successMessage && (
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              )}
              <div className="table-responsive text-nowrap">
                <div className="row mx-1">
                  <div className="col-sm-12 col-md-3">
                    <div
                      className="dataTables_length"
                      id="DataTables_Table_0_length"
                    >
                      <label>
                        <select
                          // name="DataTables_Table_0_length"
                          // aria-controls="DataTables_Table_0"
                          className="form-select"
                          onChange={handlePermissionsPerPageChange}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-9">
                    <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap me-1">
                      <div className="me-3">
                        <div
                          id="DataTables_Table_0_filter"
                          className="dataTables_filter"
                        >
                          <label>
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Search by permission name"
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="dt-buttons btn-group flex-wrap">
                        <button
                          className="btn add-new btn-primary mb-3 mb-md-0"
                          tabIndex="0"
                          aria-controls="DataTables_Table_0"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#addPermissionModal"
                        >
                          <span>Add Permission</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {loading && <p>Loading...</p>}
                {error && <p>Error: {error.message}</p>}
                {!loading && !error && (
                  <>
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Name</th>
                          <th>Description</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredPermissions.map((permission, index) => (
                          <tr key={permission.id}>
                            <td>
                              {(currentPage - 1) * permissionsPerPage +
                                index +
                                1}
                            </td>
                            <td>{permission.name}</td>
                            <td>{permission.description}</td>
                            <td>
                              <span className="text-nowrap">
                                <button
                                  className="btn btn-sm btn-icon me-2"
                                  data-bs-target="#editPermissionModal"
                                  data-bs-toggle="modal"
                                  data-bs-dismiss="modal"
                                  onClick={() =>
                                    setSelectedPermission(permission)
                                  }
                                >
                                  <i className="bx bx-edit"></i>
                                </button>
                                <button
                                  className="btn btn-sm btn-icon delete-record"
                                  onClick={() =>
                                    setSelectedPermission(permission)
                                  }
                                  data-bs-target="#deletePermissionModal"
                                  data-bs-toggle="modal"
                                >
                                  <i className="bx bx-trash"></i>
                                </button>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div
                      className="pagination d-flex justify-content-end mt-3"
                      style={{ margin: "20px", marginRight: "20px" }}
                    >
                      <button
                        className="btn btn-primary me-2"
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={handleNextPage}
                        disabled={
                          currentPage * permissionsPerPage >= totalPermissions
                        }
                      >
                        Next
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="addPermissionModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-simple">
          <div className="modal-content p-3 p-md-5">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close btn-pinned"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="text-center mb-4">
                <h3>Add New Permission</h3>
                <p>Permissions you may use and assign to your users.</p>
              </div>
              <form
                id="addPermissionForm"
                className="row"
                onSubmit={handleAddPermission}
              >
                <div className="col-12 mb-3">
                  <label className="form-label" htmlFor="modalPermissionName">
                    Permission Name
                  </label>
                  <input
                    type="text"
                    id="modalPermissionName"
                    name="modalPermissionName"
                    className="form-control"
                    placeholder="Permission Name"
                    required
                  />
                </div>
                <div className="col-12 mb-3">
                  <label
                    className="form-label"
                    htmlFor="modalPermissionDescription"
                  >
                    Permission Description
                  </label>
                  <input
                    type="text"
                    id="modalPermissionDescription"
                    name="modalPermissionDescription"
                    className="form-control"
                    placeholder="Permission Description"
                    required
                  />
                </div>
                <div className="col-12 text-center demo-vertical-spacing">
                  <button
                    type="submit"
                    className="btn btn-primary me-sm-3 me-1"
                  >
                    Create Permission
                  </button>
                  <button
                    type="reset"
                    className="btn btn-label-secondary"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Discard
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="editPermissionModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-simple">
          <div className="modal-content p-3 p-md-5">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="text-center mb-4">
                <h3>Edit Permission</h3>
              </div>
              <div className="alert alert-warning" role="alert">
                <h6 className="alert-heading mb-2">Warning</h6>
                <p className="mb-0">
                  By editing the permission name, you might break the system
                  permissions functionality. Please ensure you're absolutely
                  certain before proceeding.
                </p>
              </div>
              <form
                id="editPermissionForm"
                className="row"
                onSubmit={handleEditPermission}
              >
                <div className="col-sm-12 mb-3">
                  <label className="form-label" htmlFor="editPermissionName">
                    Permission Name
                  </label>
                  <input
                    type="text"
                    id="editPermissionName"
                    name="editPermissionName"
                    className="form-control"
                    placeholder="Permission Name"
                    required
                    defaultValue={
                      selectedPermission ? selectedPermission.name : ""
                    }
                  />
                </div>
                <div className="col-sm-12 mb-3">
                  <label
                    className="form-label"
                    htmlFor="editPermissionDescription"
                  >
                    Permission Description
                  </label>
                  <input
                    type="text"
                    id="editPermissionDescription"
                    name="editPermissionDescription"
                    className="form-control"
                    placeholder="Permission Description"
                    required
                    defaultValue={
                      selectedPermission ? selectedPermission.description : ""
                    }
                  />
                </div>
                <div className="col-12 text-center demo-vertical-spacing">
                  <button
                    type="submit"
                    className="btn btn-primary me-sm-3 me-1"
                  >
                    Update Permission
                  </button>
                  <button
                    type="reset"
                    className="btn btn-label-secondary"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Discard
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="deletePermissionModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-simple">
          <div className="modal-content p-3 p-md-5">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="text-center mb-4">
                <h3>Delete Permission</h3>
              </div>
              <div className="alert alert-warning" role="alert">
                <h6 className="alert-heading mb-2">Warning</h6>
                <p className="mb-0">
                  Are you sure you want to delete this permission? This action
                  cannot be undone.
                </p>
              </div>
              <div className="col-12 text-center demo-vertical-spacing">
                <button
                  type="button"
                  className="btn btn-danger me-sm-3 me-1"
                  onClick={handleDeletePermission}
                >
                  Delete Permission
                </button>
                <button
                  type="button"
                  className="btn btn-label-secondary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Permissions;
