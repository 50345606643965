import Register from "../components/Register";
import LoginPage from "../components/LoginPage";
import Landing from "../components/Landing";

const routesWithoutLayout = [
  { path: "/login", element: <LoginPage />, isProtected: false },
  { path: "/register", element: <Register />, isProtected: false },
  { path: "/", element: <Landing />, isProtected: false },
];

export default routesWithoutLayout;
