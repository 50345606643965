// src/graphql/permissionQueries.js
import { gql } from "@apollo/client";

export const GET_PERMISSIONS = gql`
  query Permissions($limit: Int!, $offset: Int!) {
    permissions(limit: $limit, offset: $offset) {
      id
      name
      description
    }
  }
`;

export const GET_PERMISSION = gql`
  query Permission($id: ID!) {
    permission(id: $id) {
      id
      name
      description
    }
  }
`;

export const GET_PERMISSIONS_COUNT = gql`
  query PermissionsCount {
    permissionsCount
  }
`;
