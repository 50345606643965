// require("dotenv").config();
import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { LOGIN_MUTATION } from "../graphql/queries";
import "../page-auth.css";
import { encrypt, decrypt } from "../utils/utility";

// const COOKIE_EXPIRATION_DAYS =
//   process.env.REACT_APP_COOKIE_EXPIRATION_DAYS || 7;

const COOKIE_EXPIRATION_DAYS = 7;

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const LoginPage = () => {
  // console.log("API URLllllll:", process.env.REACT_APP_API_URL);
  // console.log("App Name:", process.env.REACT_APP_NAME);

  const navigate = useNavigate();
  const location = useLocation(); // Use location hook to get the URL parameters
  const [rememberMe, setRememberMe] = useState(false);
  const [loginUser, { loading, error }] = useMutation(LOGIN_MUTATION);
  const [showPassword, setShowPassword] = useState(false);
  const [customError, setCustomError] = useState("");
  const [verifiedMessage, setVerifiedMessage] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    const savedEmail = Cookies.get("em");
    const savedPassword = Cookies.get("pd");
    if (savedEmail && savedPassword) {
      setRememberMe(true);
      setInitialValues({
        email: decrypt(savedEmail),
        password: decrypt(savedPassword),
      });
    }

    const queryParams = new URLSearchParams(location.search);
    const verified = queryParams.get("verified");
    const message = queryParams.get("message");

    if (verified === "true") {
      setVerifiedMessage("Email verified successfully.");
    } else if (verified === "false" && message) {
      setCustomError(decodeURIComponent(message));
    }
  }, [location]);

  return (
    <div className="container-xxl">
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner">
          <div className="card">
            <div className="card-body">
              <div className="app-brand justify-content-center">
                <Link to="/" className="app-brand-link gap-2">
                  <span className="app-brand-logo demo"></span>
                  <span className="app-brand-text demo text-body fw-bolder">
                    {process.env.REACT_APP_NAME}
                  </span>
                </Link>
              </div>
              <h4 className="mb-2">
                Welcome to {process.env.REACT_APP_NAME}! 👋
              </h4>
              <p className="mb-4">
                Please sign-in to your account and start the adventure
              </p>
              {verifiedMessage && (
                <p className="text-success">{verifiedMessage}</p>
              )}
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    const response = await loginUser({
                      variables: {
                        email: values.email,
                        password: values.password,
                      },
                    });

                    const { token, status, role } = response.data.loginUser;

                    if (parseInt(status) === 1) {
                      // Set token in cookies
                      Cookies.set("token", token, {
                        expires: rememberMe
                          ? COOKIE_EXPIRATION_DAYS
                          : undefined,
                      });
                      Cookies.set("role", encrypt(role.id), {
                        expires: rememberMe
                          ? COOKIE_EXPIRATION_DAYS
                          : undefined,
                      });
                      Cookies.set("roleName", encrypt(role.name), {
                        expires: rememberMe
                          ? COOKIE_EXPIRATION_DAYS
                          : undefined,
                      });

                      Cookies.set("us", encrypt(values.email), {
                        expires: rememberMe
                          ? COOKIE_EXPIRATION_DAYS
                          : undefined,
                      });
                      // Set permissions as a JSON string
                      const permissionsArray = role.permissions.map(
                        (p) => p.name
                      );
                      Cookies.set(
                        "permissions",
                        encrypt(JSON.stringify(permissionsArray), {
                          expires: rememberMe
                            ? COOKIE_EXPIRATION_DAYS
                            : undefined,
                        })
                      );

                      if (rememberMe) {
                        Cookies.set("em", encrypt(values.email), {
                          expires: COOKIE_EXPIRATION_DAYS,
                        });
                        Cookies.set("pd", encrypt(values.password), {
                          expires: COOKIE_EXPIRATION_DAYS,
                        });
                      } else {
                        Cookies.remove("em");
                        Cookies.remove("pd");
                      }
                      navigate("/dashboard");
                    } else {
                      setCustomError("User is inactive, contact admin.");
                    }
                  } catch (e) {
                    setCustomError("Login failed. Please try again.");
                    console.error("Login failed", e);
                  }
                  setSubmitting(false);
                }}
              >
                {({ isSubmitting, setFieldValue }) => (
                  <Form>
                    <div className="mb-3">
                      {/* {{process.env.REACT_APP_NAME}} */}
                      <label htmlFor="email" className="form-label">
                        Email or Username
                      </label>
                      <Field
                        name="email"
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder="Enter your email or username"
                        autoFocus
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="mb-3 form-password-toggle">
                      <div className="d-flex justify-content-between">
                        <label className="form-label" htmlFor="password">
                          Password
                        </label>
                        <a href="auth-forgot-password-basic.html">
                          <small>Forgot Password?</small>
                        </a>
                      </div>
                      <div className="input-group input-group-merge">
                        <Field
                          name="password"
                          type={showPassword ? "text" : "password"}
                          id="password"
                          className="form-control"
                          placeholder="••••••••"
                          aria-describedby="password"
                        />
                        <span
                          className="input-group-text cursor-pointer"
                          onClick={togglePasswordVisibility}
                        >
                          <i
                            className={
                              showPassword ? "bx bx-show" : "bx bx-hide"
                            }
                          ></i>
                        </span>
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="mb-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="remember-me"
                          checked={rememberMe}
                          onChange={(e) => setRememberMe(e.target.checked)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="remember-me"
                        >
                          Remember Me
                        </label>
                      </div>
                    </div>
                    <div className="mb-3">
                      <button
                        className="btn btn-primary d-grid w-100"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {loading ? "Signing in..." : "Sign in"}
                      </button>
                    </div>
                    {customError && (
                      <p className="text-danger">{customError}</p>
                    )}
                    {error && (
                      <p className="text-danger">
                        Login failed. Please try again.
                      </p>
                    )}
                  </Form>
                )}
              </Formik>
              <p className="text-center">
                <span>New on our platform?</span>
                <Link to="/register" className="nav-item nav-link px-0 me-xl-4">
                  <span>Create an account</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
