// src/graphql/articleMutations.js
import { gql } from "@apollo/client";

export const ADD_ARTICLE = gql`
  mutation AddArticle($title: String!, $content: String!, $tags: [String!]) {
    addArticle(title: $title, content: $content, tags: $tags) {
      id
      title
      content
      tags
      author {
        id
        username
      }
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_ARTICLE = gql`
  mutation UpdateArticle(
    $id: ID!
    $title: String
    $content: String
    $tags: [String]
  ) {
    updateArticle(id: $id, title: $title, content: $content, tags: $tags) {
      id
      title
      content
      tags
      author {
        id
        username
      }
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_ARTICLE = gql`
  mutation DeleteArticle($id: ID!) {
    deleteArticle(id: $id) {
      id
    }
  }
`;
