import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import Cookies from "js-cookie";
import { decrypt } from "../utils/utility";

const Sidebar = () => {
  const location = useLocation();
  const menuInnerRef = useRef(null);
  const [isShadowVisible, setIsShadowVisible] = useState(false);
  const [openMenus, setOpenMenus] = useState({});
  const userPermissions = Cookies.get("permissions")
    ? JSON.parse(decrypt(Cookies.get("permissions")))
    : [];

  useEffect(() => {
    const menuInnerContainer = menuInnerRef.current;
    let ps;

    if (menuInnerContainer) {
      ps = new PerfectScrollbar(menuInnerContainer);

      const handleScroll = () => {
        if (menuInnerContainer.scrollTop > 0) {
          setIsShadowVisible(true);
        } else {
          setIsShadowVisible(false);
        }
      };

      menuInnerContainer.addEventListener("scroll", handleScroll);

      return () => {
        menuInnerContainer.removeEventListener("scroll", handleScroll);
        if (ps) {
          ps.destroy();
        }
      };
    }
  }, []);

  const toggleMenu = (menuName) => {
    setOpenMenus((prevOpenMenus) => ({
      ...prevOpenMenus,
      [menuName]: !prevOpenMenus[menuName],
    }));
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  useEffect(() => {
    // Determine which menus should be open based on the current path
    if (location.pathname.startsWith("/user")) {
      setOpenMenus((prevOpenMenus) => ({
        ...prevOpenMenus,
        users: true,
      }));
    }
    if (location.pathname.startsWith("/pages-account-settings")) {
      setOpenMenus((prevOpenMenus) => ({
        ...prevOpenMenus,
        articlesSettings: true,
        settings: true,
      }));
    }
  }, [location.pathname]);

  const hasPermission = (permission) => {
    return userPermissions.includes(permission);
  };

  return (
    <aside
      id="layout-menu"
      className="layout-menu menu-vertical menu bg-menu-theme"
    >
      <div className="app-brand demo">
        <Link to="/dashboard" className="app-brand-link">
          <span className="app-brand-logo demo"></span>
          <span className="app-brand-text demo menu-text fw-bolder ms-2">
            Scribblerspot
          </span>
        </Link>

        <Link
          to="#"
          className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none"
          onClick={(e) => e.preventDefault()}
        >
          <i className="bx bx-chevron-left bx-sm align-middle"></i>
        </Link>
      </div>

      <div
        className={`menu-inner-shadow ${isShadowVisible ? "visible" : ""}`}
      ></div>

      <ul className="menu-inner py-1" ref={menuInnerRef}>
        {hasPermission("dashboard:access") && (
          <li className={`menu-item ${isActive("/dashboard") ? "active" : ""}`}>
            <Link to="/dashboard" className="menu-link">
              <i className="menu-icon tf-icons bx bx-home-circle"></i>
              <div data-i18n="Analytics">Dashboard</div>
            </Link>
          </li>
        )}

        {hasPermission("users:access") && (
          <>
            {/* <li className="menu-header small text-uppercase">
              <span className="menu-header-text">Users</span>
            </li> */}

            <li className={`menu-item ${openMenus.users ? "open" : ""}`}>
              <Link
                to="#"
                className="menu-link menu-toggle"
                onClick={(e) => {
                  e.preventDefault();
                  toggleMenu("users");
                }}
              >
                <i className="menu-icon tf-icons bx bx-dock-top"></i>
                <div data-i18n="Account Settings">Users</div>
              </Link>
              {openMenus.users && (
                <ul className="menu-sub">
                  {hasPermission("users:access") && (
                    <li
                      className={`menu-item ${
                        isActive("/users") ? "active" : ""
                      }`}
                    >
                      <Link to="/users" className="menu-link">
                        <div data-i18n="Account">All Users</div>
                      </Link>
                    </li>
                  )}
                  {hasPermission("users:create") && (
                    <li
                      className={`menu-item ${
                        isActive("/user/create") ? "active" : ""
                      }`}
                    >
                      <Link to="/user/create" className="menu-link">
                        <div data-i18n="Notifications">Add Users</div>
                      </Link>
                    </li>
                  )}
                </ul>
              )}
            </li>
          </>
        )}

        {hasPermission("articles:access") && (
          <>
            {/* <li className="menu-header small text-uppercase">
              <span className="menu-header-text">Articles</span>
            </li> */}

            <li
              className={`menu-item ${
                openMenus.articlesSettings ? "open" : ""
              }`}
            >
              <Link
                to="#"
                className="menu-link menu-toggle"
                onClick={(e) => {
                  e.preventDefault();
                  toggleMenu("articlesSettings");
                }}
              >
                <i className="menu-icon tf-icons bx bx-dock-top"></i>
                <div data-i18n="Account Settings">Articles</div>
              </Link>
              {openMenus.articlesSettings && (
                <ul className="menu-sub">
                  {hasPermission("articles:view_own_articles") && (
                    <li
                      className={`menu-item ${
                        isActive("/my-articles") ? "active" : ""
                      }`}
                    >
                      <Link to="/my-articles" className="menu-link">
                        <div data-i18n="Account">My Articles</div>
                      </Link>
                    </li>
                  )}
                  {hasPermission("articles:access") && (
                    <li
                      className={`menu-item ${
                        isActive("/articles") ? "active" : ""
                      }`}
                    >
                      <Link to="/articles" className="menu-link">
                        <div data-i18n="Account">All Articles</div>
                      </Link>
                    </li>
                  )}
                  {hasPermission("articles:create") && (
                    <li
                      className={`menu-item ${
                        isActive("/article/create") ? "active" : ""
                      }`}
                    >
                      <Link to="/article/create" className="menu-link">
                        <div data-i18n="Notifications">Add Article</div>
                      </Link>
                    </li>
                  )}
                </ul>
              )}
            </li>
          </>
        )}

        {hasPermission("categories:access") && (
          <>
            {/* <li className="menu-header small text-uppercase">
              <span className="menu-header-text">Articles</span>
            </li> */}

            <li
              className={`menu-item ${
                openMenus.categorySettings ? "open" : ""
              }`}
            >
              <Link
                to="#"
                className="menu-link menu-toggle"
                onClick={(e) => {
                  e.preventDefault();
                  toggleMenu("categorySettings");
                }}
              >
                <i className="menu-icon tf-icons bx bx-dock-top"></i>
                <div data-i18n="Account Settings">Categories</div>
              </Link>
              {openMenus.categorySettings && (
                <ul className="menu-sub">
                  {hasPermission("categories:access") && (
                    <li
                      className={`menu-item ${
                        isActive("/my-articles") ? "active" : ""
                      }`}
                    >
                      <Link to="/categories" className="menu-link">
                        <div data-i18n="Account">Categories</div>
                      </Link>
                    </li>
                  )}
                </ul>
              )}
            </li>
          </>
        )}

        {hasPermission("access:manage") && (
          <>
            {/* <li className="menu-header small text-uppercase">
              <span className="menu-header-text">Access Management</span>
            </li> */}

            <li className={`menu-item ${openMenus.access ? "open" : ""}`}>
              <Link
                to="#"
                className="menu-link menu-toggle"
                onClick={(e) => {
                  e.preventDefault();
                  toggleMenu("access");
                }}
              >
                <i className="menu-icon tf-icons bx bx-dock-top"></i>
                <div data-i18n="Account Settings">Accesses</div>
              </Link>
              {openMenus.access && (
                <ul className="menu-sub">
                  {hasPermission("permissions:access") && (
                    <li
                      className={`menu-item ${
                        isActive("/access/permissions") ? "active" : ""
                      }`}
                    >
                      <Link to="/access/permissions" className="menu-link">
                        <div data-i18n="Access">Permissions</div>
                      </Link>
                    </li>
                  )}
                  {hasPermission("roles:access") && (
                    <li
                      className={`menu-item ${
                        isActive("/access/roles") ? "active" : ""
                      }`}
                    >
                      <Link to="/access/roles" className="menu-link">
                        <div data-i18n="Notifications">Roles</div>
                      </Link>
                    </li>
                  )}
                </ul>
              )}
            </li>
          </>
        )}

        {hasPermission("settings:access") && (
          <>
            {/* <li className="menu-header small text-uppercase">
              <span className="menu-header-text">Settings</span>
            </li> */}

            <li className={`menu-item ${openMenus.settings ? "open" : ""}`}>
              <Link
                to="#"
                className="menu-link menu-toggle"
                onClick={(e) => {
                  e.preventDefault();
                  toggleMenu("settings");
                }}
              >
                <i className="menu-icon tf-icons bx bx-dock-top"></i>
                <div data-i18n="Account Settings">Settings</div>
              </Link>
              {openMenus.settings && (
                <ul className="menu-sub">
                  {hasPermission("settings:access") && (
                    <li
                      className={`menu-item ${
                        isActive("/pages-account-settings-account")
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link
                        to="/pages-account-settings-account"
                        className="menu-link"
                      >
                        <div data-i18n="Account">Settings</div>
                      </Link>
                    </li>
                  )}
                  {hasPermission("notifications:access") && (
                    <li
                      className={`menu-item ${
                        isActive("/pages-account-settings-notifications")
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link
                        to="/pages-account-settings-notifications"
                        className="menu-link"
                      >
                        <div data-i18n="Notifications">Notifications</div>
                      </Link>
                    </li>
                  )}
                  {hasPermission("featureFlags:view") && (
                    <li
                      className={`menu-item ${
                        isActive("/pages-account-settings-connections")
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link
                        to="/pages-account-settings-connections"
                        className="menu-link"
                      >
                        <div data-i18n="Connections">Feature Flags</div>
                      </Link>
                    </li>
                  )}
                </ul>
              )}
            </li>
          </>
        )}
      </ul>
    </aside>
  );
};

export default Sidebar;
