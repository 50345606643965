// src/graphql/articleQueries.js
import { gql } from "@apollo/client";

export const GET_ARTICLES = gql`
  query Articles($limit: Int!, $offset: Int!) {
    articles(limit: $limit, offset: $offset) {
      id
      title
      tags
      author {
        id
        username
        email
      }
      content
      excerpt
      createdAt
      updatedAt
    }
  }
`;

export const GET_ARTICLE = gql`
  query Article($id: ID!) {
    article(id: $id) {
      id
      title
      tags
      author {
        id
        username
        email
      }
      content
      excerpt
      createdAt
      updatedAt
    }
  }
`;

export const GET_ARTICLES_COUNT = gql`
  query ArticlesCount {
    articlesCount
  }
`;
