import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ items }) => {
  return (
    <h4 className="fw-bold py-3 mb-4">
      {items.map((item, index) => (
        <span
          key={index}
          className={index < items.length - 1 ? "text-muted fw-light" : ""}
        >
          {index < items.length - 1 ? (
            <Link to={item.url}>{item.name}</Link>
          ) : (
            item.name
          )}
          {index < items.length - 1 && " / "}
        </span>
      ))}
    </h4>
  );
};

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string,
    })
  ).isRequired,
};

export default Breadcrumbs;
