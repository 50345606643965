// src/graphql/roleQueries.js
import { gql } from "@apollo/client";

export const GET_ROLES = gql`
  query Roles($limit: Int!, $offset: Int!) {
    roles(limit: $limit, offset: $offset) {
      id
      name
      description
      permissions {
        id
        name
      }
    }
  }
`;

export const GET_ALL_ROLES = gql`
  query AllRoles {
    roles {
      id
      name
      description
      permissions {
        id
        name
      }
    }
  }
`;

export const GET_ROLES_COUNT = gql`
  query RolesCount {
    rolesCount
  }
`;

export const GET_ROLE = gql`
  query Role($id: ID!) {
    role(id: $id) {
      id
      name
      description
      permissions {
        id
        name
      }
    }
  }
`;
