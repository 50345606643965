import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import Breadcrumbs from "./Breadcrumbs";
import {
  GET_ROLES,
  GET_ROLES_COUNT,
  ADD_ROLE,
  UPDATE_ROLE,
  DELETE_ROLE,
  GET_PERMISSIONS,
} from "../graphql/queries";

const Roles = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rolesPerPage, setRolesPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const { loading, error, data, refetch } = useQuery(GET_ROLES, {
    variables: {
      limit: rolesPerPage,
      offset: (currentPage - 1) * rolesPerPage,
    },
  });

  const { data: countData } = useQuery(GET_ROLES_COUNT);
  const { data: permissionsData } = useQuery(GET_PERMISSIONS, {
    variables: { limit: 100, offset: 0 },
  });

  const [addRole] = useMutation(ADD_ROLE, {
    onCompleted: () => {
      setSuccessMessage("Role added successfully!");
      refetch();
      closeModal("addRoleModal");
    },
    onError: (error) => {
      console.error("Error adding role:", error);
    },
  });

  const [updateRole] = useMutation(UPDATE_ROLE, {
    onCompleted: () => {
      setSuccessMessage("Role updated successfully!");
      refetch();
      closeModal("editRoleModal");
    },
    onError: (error) => {
      console.error("Error updating role:", error);
    },
  });

  const [deleteRole] = useMutation(DELETE_ROLE, {
    onCompleted: () => {
      setSuccessMessage("Role deleted successfully!");
      refetch();
      closeModal("deleteRoleModal");
    },
    onError: (error) => {
      console.error("Error deleting role:", error);
    },
  });

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    if (currentPage * rolesPerPage < totalRoles) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleRolesPerPageChange = (event) => {
    const newRolesPerPage = parseInt(event.target.value, 10);
    setRolesPerPage(newRolesPerPage);
    setCurrentPage(1);
  };

  const handleAddRole = (e) => {
    e.preventDefault();
    const { modalRoleName, modalRoleDescription } = e.target.elements;
    addRole({
      variables: {
        name: modalRoleName.value,
        description: modalRoleDescription.value,
        permissions: selectedPermissions,
      },
    });
  };

  const handleEditRole = (e) => {
    e.preventDefault();
    const { editRoleName, editRoleDescription } = e.target.elements;
    updateRole({
      variables: {
        id: selectedRole.id,
        name: editRoleName.value,
        description: editRoleDescription.value,
        permissions: selectedPermissions,
      },
    });
  };

  const handleDeleteRole = () => {
    deleteRole({ variables: { id: selectedRole.id } });
  };

  const closeModal = (modalId) => {
    const modal = window.bootstrap.Modal.getInstance(
      document.getElementById(modalId)
    );
    modal.hide();
    document.body.classList.remove("modal-open");
    const backdrop = document.querySelector(".modal-backdrop");
    if (backdrop) backdrop.remove();
  };

  const filteredRoles = data
    ? data.roles.filter((role) =>
        role.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  const totalRoles = countData ? countData.rolesCount : 0;

  return (
    <div className="content-wrapper">
      <div className="container-xxl flex-grow-1 container-p-y">
        <Breadcrumbs items={[{ name: "Roles", url: "/roles" }]} />
        <div className="row">
          <div className="col-lg-12 mb-4 order-0">
            <div className="card">
              <h5 className="card-header">Roles</h5>
              {successMessage && (
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              )}
              <div className="table-responsive text-nowrap">
                <div className="row mx-1">
                  <div className="col-sm-12 col-md-3">
                    <div
                      className="dataTables_length"
                      id="DataTables_Table_0_length"
                    >
                      <label>
                        <select
                          name="DataTables_Table_0_length"
                          aria-controls="DataTables_Table_0"
                          className="form-select"
                          value={rolesPerPage}
                          onChange={handleRolesPerPageChange}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-9">
                    <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap me-1">
                      <div className="me-3">
                        <div
                          id="DataTables_Table_0_filter"
                          className="dataTables_filter"
                        >
                          <label>
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Search by role name"
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="dt-buttons btn-group flex-wrap">
                        <button
                          className="btn add-new btn-primary mb-3 mb-md-0"
                          tabIndex="0"
                          aria-controls="DataTables_Table_0"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#addRoleModal"
                        >
                          <span>Add Role</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {loading && <p>Loading...</p>}
                {error && <p>Error: {error.message}</p>}
                {!loading && !error && (
                  <>
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Name</th>
                          <th>Description</th>
                          <th>Permissions</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredRoles.map((role, index) => (
                          <tr key={role.id}>
                            <td>
                              {(currentPage - 1) * rolesPerPage + index + 1}
                            </td>
                            <td>{role.name}</td>
                            <td>{role.description}</td>
                            <td
                              style={{
                                width: "20%",
                                maxWidth: "200px",
                                wordWrap: "break-word",
                                whiteSpace: "normal",
                              }}
                            >
                              {role.permissions
                                .map((permission) => permission.name)
                                .join(", ")}
                            </td>
                            <td>
                              <span className="text-nowrap">
                                <button
                                  className="btn btn-sm btn-icon me-2"
                                  data-bs-target="#editRoleModal"
                                  data-bs-toggle="modal"
                                  data-bs-dismiss="modal"
                                  onClick={() => {
                                    setSelectedRole(role);
                                    setSelectedPermissions(
                                      role.permissions.map((p) => p.id)
                                    );
                                  }}
                                >
                                  <i className="bx bx-edit"></i>
                                </button>
                                <button
                                  className="btn btn-sm btn-icon delete-record"
                                  onClick={() => setSelectedRole(role)}
                                  data-bs-target="#deleteRoleModal"
                                  data-bs-toggle="modal"
                                >
                                  <i className="bx bx-trash"></i>
                                </button>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div
                      className="pagination d-flex justify-content-end mt-3"
                      style={{ margin: "20px", marginRight: "20px" }}
                    >
                      <button
                        className="btn btn-primary me-2"
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={handleNextPage}
                        disabled={currentPage * rolesPerPage >= totalRoles}
                      >
                        Next
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="addRoleModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-simple">
          <div className="modal-content p-3 p-md-5">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close btn-pinned"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="text-center mb-4">
                <h3>Add New Role</h3>
                <p>Assign permissions to the role.</p>
              </div>
              <form id="addRoleForm" className="row" onSubmit={handleAddRole}>
                <div className="col-12 mb-3">
                  <label className="form-label" htmlFor="modalRoleName">
                    Role Name
                  </label>
                  <input
                    type="text"
                    id="modalRoleName"
                    name="modalRoleName"
                    className="form-control"
                    placeholder="Role Name"
                    required
                  />
                </div>
                <div className="col-12 mb-3">
                  <label className="form-label" htmlFor="modalRoleDescription">
                    Role Description
                  </label>
                  <input
                    type="text"
                    id="modalRoleDescription"
                    name="modalRoleDescription"
                    className="form-control"
                    placeholder="Role Description"
                    required
                  />
                </div>
                <div className="col-12 mb-3">
                  <label className="form-label" htmlFor="modalRolePermissions">
                    Permissions
                  </label>
                  <select
                    id="modalRolePermissions"
                    name="modalRolePermissions"
                    className="form-select"
                    multiple
                    value={selectedPermissions}
                    onChange={(e) =>
                      setSelectedPermissions(
                        Array.from(
                          e.target.selectedOptions,
                          (option) => option.value
                        )
                      )
                    }
                  >
                    {permissionsData &&
                      permissionsData.permissions.map((permission) => (
                        <option key={permission.id} value={permission.id}>
                          {permission.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-12 text-center demo-vertical-spacing">
                  <button
                    type="submit"
                    className="btn btn-primary me-sm-3 me-1"
                  >
                    Create Role
                  </button>
                  <button
                    type="reset"
                    className="btn btn-label-secondary"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Discard
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="editRoleModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-simple">
          <div className="modal-content p-3 p-md-5">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="text-center mb-4">
                <h3>Edit Role</h3>
              </div>
              <div className="alert alert-warning" role="alert">
                <h6 className="alert-heading mb-2">Warning</h6>
                <p className="mb-0">
                  Editing the role might impact system functionality. Ensure you
                  are certain before proceeding.
                </p>
              </div>
              <form id="editRoleForm" className="row" onSubmit={handleEditRole}>
                <div className="col-sm-12 mb-3">
                  <label className="form-label" htmlFor="editRoleName">
                    Role Name
                  </label>
                  <input
                    type="text"
                    id="editRoleName"
                    name="editRoleName"
                    className="form-control"
                    placeholder="Role Name"
                    required
                    defaultValue={selectedRole ? selectedRole.name : ""}
                  />
                </div>
                <div className="col-sm-12 mb-3">
                  <label className="form-label" htmlFor="editRoleDescription">
                    Role Description
                  </label>
                  <input
                    type="text"
                    id="editRoleDescription"
                    name="editRoleDescription"
                    className="form-control"
                    placeholder="Role Description"
                    required
                    defaultValue={selectedRole ? selectedRole.description : ""}
                  />
                </div>
                <div className="col-sm-12 mb-3">
                  <label className="form-label" htmlFor="editRolePermissions">
                    Permissions
                  </label>
                  <select
                    id="editRolePermissions"
                    name="editRolePermissions"
                    className="form-select"
                    multiple
                    value={selectedPermissions}
                    onChange={(e) =>
                      setSelectedPermissions(
                        Array.from(
                          e.target.selectedOptions,
                          (option) => option.value
                        )
                      )
                    }
                  >
                    {permissionsData &&
                      permissionsData.permissions.map((permission) => (
                        <option key={permission.id} value={permission.id}>
                          {permission.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-12 text-center demo-vertical-spacing">
                  <button
                    type="submit"
                    className="btn btn-primary me-sm-3 me-1"
                  >
                    Update Role
                  </button>
                  <button
                    type="reset"
                    className="btn btn-label-secondary"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Discard
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="deleteRoleModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-simple">
          <div className="modal-content p-3 p-md-5">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="text-center mb-4">
                <h3>Delete Role</h3>
              </div>
              <div className="alert alert-warning" role="alert">
                <h6 className="alert-heading mb-2">Warning</h6>
                <p className="mb-0">
                  Are you sure you want to delete this role? This action cannot
                  be undone.
                </p>
              </div>
              <div className="col-12 text-center demo-vertical-spacing">
                <button
                  type="button"
                  className="btn btn-danger me-sm-3 me-1"
                  onClick={handleDeleteRole}
                >
                  Delete Role
                </button>
                <button
                  type="button"
                  className="btn btn-label-secondary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roles;
