import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import Breadcrumbs from "../Breadcrumbs";
import {
  GET_ARTICLES,
  GET_ARTICLES_COUNT,
  // ADD_ARTICLE,
  // UPDATE_ARTICLE,
  // DELETE_ARTICLE,
} from "../../graphql/queries";
import { Link } from "react-router-dom";

const MyArticles = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [articlesPerPage, setArticlesPerPage] = useState(10);

  const { loading, error, data, refetch } = useQuery(GET_ARTICLES, {
    variables: {
      limit: articlesPerPage,
      offset: (currentPage - 1) * articlesPerPage,
    },
  });

  const { data: countData } = useQuery(GET_ARTICLES_COUNT);

  // const [addArticle] = useMutation(ADD_ARTICLE, {
  //   onCompleted: () => {
  //     setSuccessMessage("Article added successfully!");
  //     refetch();
  //     closeModal("addArticleModal");
  //   },
  //   onError: (error) => {
  //     console.error("Error adding article:", error);
  //   },
  // });

  // const [updateArticle] = useMutation(UPDATE_ARTICLE, {
  //   onCompleted: () => {
  //     setSuccessMessage("Article updated successfully!");
  //     refetch();
  //     closeModal("editArticleModal");
  //   },
  //   onError: (error) => {
  //     console.error("Error updating article:", error);
  //   },
  // });

  // const [deleteArticle] = useMutation(DELETE_ARTICLE, {
  //   onCompleted: () => {
  //     setSuccessMessage("Article deleted successfully!");
  //     refetch();
  //     closeModal("deleteArticleModal");
  //   },
  //   onError: (error) => {
  //     console.error("Error deleting article:", error);
  //   },
  // });

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    if (currentPage * articlesPerPage < totalArticles) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleAddArticle = (e) => {
    e.preventDefault();
    const { modalArticleTitle, modalArticleContent } = e.target.elements;
    // addArticle({
    //   variables: {
    //     title: modalArticleTitle.value,
    //     content: modalArticleContent.value,
    //   },
    // });
  };

  const handleEditArticle = (e) => {
    e.preventDefault();
    const { editArticleTitle, editArticleContent } = e.target.elements;
    // updateArticle({
    //   variables: {
    //     id: selectedArticle.id,
    //     title: editArticleTitle.value,
    //     content: editArticleContent.value,
    //   },
    // });
  };

  const handleDeleteArticle = () => {
    // deleteArticle({ variables: { id: selectedArticle.id } });
  };

  const closeModal = (modalId) => {
    const modal = window.bootstrap.Modal.getInstance(
      document.getElementById(modalId)
    );
    modal.hide();
    document.body.classList.remove("modal-open");
    const backdrop = document.querySelector(".modal-backdrop");
    if (backdrop) backdrop.remove();
  };

  const handleArticlesPerPageChange = (event) => {
    const newArticlesPerPage = parseInt(event.target.value, 10);
    setArticlesPerPage(newArticlesPerPage);
    setCurrentPage(1);
    refetch({
      limit: newArticlesPerPage,
      offset: 0,
    });
  };

  const filteredArticles = data
    ? data.articles.filter((article) =>
        article.title.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  const totalArticles = countData ? countData.articlesCount : 0;

  return (
    <div className="content-wrapper">
      <div className="container-xxl flex-grow-1 container-p-y">
        <Breadcrumbs items={[{ name: "Articles", url: "/articles" }]} />
        <div className="row">
          <div className="col-lg-12 mb-4 order-0">
            <div className="card">
              <h5 className="card-header">Create Article</h5>
              {successMessage && (
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              )}
              <div className="table-responsive text-nowrap">
                <div className="row mx-1">
                  <div className="col-sm-12 col-md-3">
                    <div
                      className="dataTables_length"
                      id="DataTables_Table_0_length"
                    >
                      <label>
                        <select
                          className="form-select"
                          onChange={handleArticlesPerPageChange}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-9">
                    <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap me-1">
                      <div className="me-3">
                        <div
                          id="DataTables_Table_0_filter"
                          className="dataTables_filter"
                        >
                          <label>
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Search by article title"
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="dt-buttons btn-group flex-wrap">
                        <button
                          className="btn add-new btn-primary mb-3 mb-md-0"
                          tabIndex="0"
                          aria-controls="DataTables_Table_0"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#addArticleModal"
                        >
                          <span>Add Article</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {loading && <p>Loading...</p>}
                {error && <p>Error: {error.message}</p>}
                {!loading && !error && (
                  <>
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Title</th>
                          <th>Excerpt</th>
                          <th>Username</th>
                          <th>Email</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredArticles.map((article, index) => (
                          <tr key={article.id}>
                            <td>
                              {(currentPage - 1) * articlesPerPage + index + 1}
                            </td>
                            <td>
                              <Link
                                to={`/article/${article.id}`}
                                key={article.id}
                              >
                                {article.title}
                              </Link>
                            </td>
                            <td
                              style={{
                                width: "20%",
                                maxWidth: "200px",
                                wordWrap: "break-word",
                                whiteSpace: "normal",
                              }}
                            >
                              {article.excerpt}
                            </td>
                            <td>{article.author.username}</td>
                            <td>{article.author.email}</td>
                            <td>
                              <span className="text-nowrap">
                                <button
                                  className="btn btn-sm btn-icon me-2"
                                  data-bs-target="#editArticleModal"
                                  data-bs-toggle="modal"
                                  data-bs-dismiss="modal"
                                  onClick={() => setSelectedArticle(article)}
                                >
                                  <i className="bx bx-edit"></i>
                                </button>
                                <button
                                  className="btn btn-sm btn-icon delete-record"
                                  onClick={() => setSelectedArticle(article)}
                                  data-bs-target="#deleteArticleModal"
                                  data-bs-toggle="modal"
                                >
                                  <i className="bx bx-trash"></i>
                                </button>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div
                      className="pagination d-flex justify-content-end mt-3"
                      style={{ margin: "20px", marginRight: "20px" }}
                    >
                      <button
                        className="btn btn-primary me-2"
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={handleNextPage}
                        disabled={
                          currentPage * articlesPerPage >= totalArticles
                        }
                      >
                        Next
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="addArticleModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-simple">
          <div className="modal-content p-3 p-md-5">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close btn-pinned"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="text-center mb-4">
                <h3>Add New Article</h3>
                <p>Create a new article for your blog.</p>
              </div>
              <form
                id="addArticleForm"
                className="row"
                onSubmit={handleAddArticle}
              >
                <div className="col-12 mb-3">
                  <label className="form-label" htmlFor="modalArticleTitle">
                    Article Title
                  </label>
                  <input
                    type="text"
                    id="modalArticleTitle"
                    name="modalArticleTitle"
                    className="form-control"
                    placeholder="Article Title"
                    required
                  />
                </div>
                <div className="col-12 mb-3">
                  <label className="form-label" htmlFor="modalArticleContent">
                    Article Content
                  </label>
                  <textarea
                    id="modalArticleContent"
                    name="modalArticleContent"
                    className="form-control"
                    placeholder="Article Content"
                    required
                  ></textarea>
                </div>
                <div className="col-12 text-center demo-vertical-spacing">
                  <button
                    type="submit"
                    className="btn btn-primary me-sm-3 me-1"
                  >
                    Create Article
                  </button>
                  <button
                    type="reset"
                    className="btn btn-label-secondary"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Discard
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="editArticleModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-simple">
          <div className="modal-content p-3 p-md-5">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="text-center mb-4">
                <h3>Edit Article</h3>
              </div>
              <div className="alert alert-warning" role="alert">
                <h6 className="alert-heading mb-2">Warning</h6>
                <p className="mb-0">
                  By editing the article, ensure you save the changes to
                  maintain data integrity.
                </p>
              </div>
              <form
                id="editArticleForm"
                className="row"
                onSubmit={handleEditArticle}
              >
                <div className="col-sm-12 mb-3">
                  <label className="form-label" htmlFor="editArticleTitle">
                    Article Title
                  </label>
                  <input
                    type="text"
                    id="editArticleTitle"
                    name="editArticleTitle"
                    className="form-control"
                    placeholder="Article Title"
                    required
                    defaultValue={selectedArticle ? selectedArticle.title : ""}
                  />
                </div>
                <div className="col-sm-12 mb-3">
                  <label className="form-label" htmlFor="editArticleContent">
                    Article Content
                  </label>
                  <textarea
                    id="editArticleContent"
                    name="editArticleContent"
                    className="form-control"
                    placeholder="Article Content"
                    required
                    defaultValue={
                      selectedArticle ? selectedArticle.content : ""
                    }
                  ></textarea>
                </div>
                <div className="col-12 text-center demo-vertical-spacing">
                  <button
                    type="submit"
                    className="btn btn-primary me-sm-3 me-1"
                  >
                    Update Article
                  </button>
                  <button
                    type="reset"
                    className="btn btn-label-secondary"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Discard
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="deleteArticleModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-simple">
          <div className="modal-content p-3 p-md-5">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="text-center mb-4">
                <h3>Delete Article</h3>
              </div>
              <div className="alert alert-warning" role="alert">
                <h6 className="alert-heading mb-2">Warning</h6>
                <p className="mb-0">
                  Are you sure you want to delete this article? This action
                  cannot be undone.
                </p>
              </div>
              <div className="col-12 text-center demo-vertical-spacing">
                <button
                  type="button"
                  className="btn btn-danger me-sm-3 me-1"
                  onClick={handleDeleteArticle}
                >
                  Delete Article
                </button>
                <button
                  type="button"
                  className="btn btn-label-secondary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyArticles;
