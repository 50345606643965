// dateHelpers.js

export const formatDate = (timestamp) => {
  if (!timestamp) return ""; // Handle case where timestamp is undefined or null

  try {
    const dateObj = new Date(parseInt(timestamp, 10)); // Convert timestamp to integer
    if (isNaN(dateObj.getTime())) {
      return "Invalid Date"; // Handle invalid date format
    } else {
      const formattedDate = dateObj.toISOString().slice(0, 10); // YYYY-MM-DD format
      return formattedDate;
    }
  } catch (error) {
    console.error("Error while formatting date:", error);
    return "Invalid Date";
  }
};
