import React, { useState, useRef } from "react";
import ReactQuill from "react-quill";

import { useQuery } from "@apollo/client";

import Breadcrumbs from "../Breadcrumbs";

import "../../css/create-article.css";
import { useDropzone } from "react-dropzone";
import "react-quill/dist/quill.snow.css";
import axios from "axios"; // Optional: for handling file uploads

import { GET_PERMISSIONS } from "../../graphql/queries";

const ArticlesCreate = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [successMessage, setSuccessMessage] = useState("");
  const [permissionsPerPage, setPermissionsPerPage] = useState(10);

  const { loading, error, data, refetch } = useQuery(GET_PERMISSIONS, {
    variables: {
      limit: permissionsPerPage,
      offset: (currentPage - 1) * permissionsPerPage,
    },
  });

  const [title, setTitle] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const quillRef = useRef(null); // Create a ref for the Quill editor

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const handleSave = () => {
    console.log("Title:", title);
    console.log("Saved Content:", editorContent);
    // Send editorContent to your backend server
  };

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    // Example: uploading to a server
    // const response = await axios.post('/upload-endpoint', formData);
    // return response.data.url;

    // Example: using a placeholder URL for the image
    return URL.createObjectURL(file);
  };

  const insertImage = (file) => {
    handleImageUpload(file).then((url) => {
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection();

      if (range) {
        // There is a selection, insert the image at the selection
        quill.insertEmbed(range.index, "image", url);
      } else {
        // No selection, set the cursor at the end and insert the image
        quill.setSelection(quill.getLength(), 0);
        quill.insertEmbed(quill.getLength(), "image", url);
      }
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      insertImage(acceptedFiles[0]);
    },
  });

  return (
    <div className="content-wrapper">
      <div className="container-xxl flex-grow-1 container-p-y">
        <Breadcrumbs
          items={[
            { name: "Articles", url: "/articles" },
            { name: "Create", url: "" },
          ]}
        />
        <div className="row">
          <div className="col-lg-12 mb-4 order-0">
            <div className="card">
              <h5 className="card-header">Write an Article</h5>
              {successMessage && (
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              )}
              <div className="table-responsive text-nowrap editor-class">
                {loading && <p>Loading...</p>}
                {error && <p>Error: {error.message}</p>}
                {!loading && !error && (
                  <>
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        value={title}
                        onChange={handleTitleChange}
                        placeholder="Enter the title of your article"
                      />
                    </div>

                    <ReactQuill
                      ref={quillRef}
                      value={editorContent}
                      onChange={handleEditorChange}
                      modules={{
                        toolbar: [
                          [{ header: "1" }, { header: "2" }, { font: [] }],
                          [{ list: "ordered" }, { list: "bullet" }],
                          ["bold", "italic", "underline", "strike"],
                          [{ color: [] }, { background: [] }],
                          [{ script: "sub" }, { script: "super" }],
                          [{ align: [] }],
                          ["link", "image"],
                          ["clean"],
                        ],
                      }}
                    />
                    <div className="button-container">
                      <button className="btn btn-primary" onClick={handleSave}>
                        Save Article
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticlesCreate;
