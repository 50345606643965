import "../css/page-misc.css";
import pageError from "../img/page-misc-error-light.png";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div class="container-xxl container-p-y">
      <div class="misc-wrapper">
        <h2 class="mb-2 mx-2">Page Not Found :(</h2>
        <p class="mb-4 mx-2">
          Oops! 😖 The requested URL was not found on this server.
        </p>
        <button onClick={() => navigate(-1)} className="btn btn-primary">
          Go Back
        </button>
        <div class="mt-3">
          <img
            src={pageError}
            alt="page-misc-error-light"
            width="500"
            class="img-fluid"
            data-app-dark-img="illustrations/page-misc-error-dark.png"
            data-app-light-img="illustrations/page-misc-error-light.png"
          />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
