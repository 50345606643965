// src/graphql/permissionMutations.js
import { gql } from "@apollo/client";

export const ADD_PERMISSION = gql`
  mutation AddPermission($name: String!, $description: String!) {
    addPermission(name: $name, description: $description) {
      id
      name
      description
    }
  }
`;

export const UPDATE_PERMISSION = gql`
  mutation UpdatePermission($id: ID!, $name: String!, $description: String!) {
    updatePermission(id: $id, name: $name, description: $description) {
      id
      name
      description
    }
  }
`;

export const DELETE_PERMISSION = gql`
  mutation DeletePermission($id: ID!) {
    deletePermission(id: $id) {
      id
    }
  }
`;
