import React from "react";
import { Link } from "react-router-dom";
// import notificationIcon from "../bx-bell.png";
import { logout } from "../utils/logout";
import Cookies from "js-cookie";
import { decrypt } from "../utils/utility";

const Header = () => {
  return (
    <nav
      className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
      id="layout-navbar"
    >
      <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
        <Link to="/" className="nav-item nav-link px-0 me-xl-4">
          <i className="bx bx-menu bx-sm"></i>
        </Link>
      </div>

      <div
        className="navbar-nav-right d-flex align-items-center"
        id="navbar-collapse"
      >
        <div className="navbar-nav align-items-center">
          <div className="nav-item d-flex align-items-center">
            <i className="bx bx-search fs-4 lh-0"></i>
            <input
              type="text"
              className="form-control border-0 shadow-none"
              placeholder="Search..."
              aria-label="Search..."
            />
          </div>
        </div>

        <ul className="navbar-nav flex-row align-items-center ms-auto">
          {/* <li className="nav-item dropdown-notifications navbar-dropdown dropdown me-3 me-xl-1">
            <Link
              className="nav-link dropdown-toggle hide-arrow show"
              to="/"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              aria-expanded="true"
            >
              <i className="bx bx-bell bx-sm"></i>
              <span className="badge bg-danger rounded-pill badge-notifications">
                5
              </span>
            </Link>
            <ul
              className="dropdown-menu dropdown-menu-end py- show"
              data-bs-popper="static"
            >
              <li className="dropdown-menu-header border-bottom">
                <div className="dropdown-header d-flex align-items-center py-3">
                  <h5 className="text-body mb-0 me-auto">Notification</h5>
                  <Link
                    to="/"
                    className="dropdown-notifications-all text-body"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    aria-label="Mark all as read"
                    data-bs-original-title="Mark all as read"
                  >
                    <i className="bx fs-4 bx-envelope-open"></i>
                  </Link>
                </div>
              </li>
              <li className="dropdown-notifications-list scrollable-container ps">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item list-group-item-action dropdown-notifications-item">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar"></div>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mb-1">Congratulation Lettie 🎉</h6>
                        <p className="mb-0">
                          Won the monthly best seller gold badge
                        </p>
                        <small className="text-muted">1h ago</small>
                      </div>
                      <div className="flex-shrink-0 dropdown-notifications-actions">
                        <Link to="/" className="dropdown-notifications-read">
                          <span className="badge badge-dot"></span>
                        </Link>
                        <Link to="/" className="dropdown-notifications-archive">
                          <span className="bx bx-x"></span>
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item list-group-item-action dropdown-notifications-item">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar">
                          <span className="avatar-initial rounded-circle bg-label-danger">
                            CF
                          </span>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mb-1">Charles Franklin</h6>
                        <p className="mb-0">Accepted your connection</p>
                        <small className="text-muted">12hr ago</small>
                      </div>
                      <div className="flex-shrink-0 dropdown-notifications-actions">
                        <Link to="/" className="dropdown-notifications-read">
                          <span className="badge badge-dot"></span>
                        </Link>
                        <Link to="/" className="dropdown-notifications-archive">
                          <span className="bx bx-x"></span>
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar"></div>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mb-1">New Message ✉️</h6>
                        <p className="mb-0">
                          You have new message from Natalie
                        </p>
                        <small className="text-muted">1h ago</small>
                      </div>
                      <div className="flex-shrink-0 dropdown-notifications-actions">
                        <Link to="/" className="dropdown-notifications-read">
                          <span className="badge badge-dot"></span>
                        </Link>
                        <Link to="/" className="dropdown-notifications-archive">
                          <span className="bx bx-x"></span>
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item list-group-item-action dropdown-notifications-item">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar">
                          <span className="avatar-initial rounded-circle bg-label-success">
                            <i className="bx bx-cart"></i>
                          </span>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mb-1">Whoo! You have new order 🛒 </h6>
                        <p className="mb-0">ACME Inc. made new order $1,154</p>
                        <small className="text-muted">1 day ago</small>
                      </div>
                      <div className="flex-shrink-0 dropdown-notifications-actions">
                        <Link to="/" className="dropdown-notifications-read">
                          <span className="badge badge-dot"></span>
                        </Link>
                        <Link to="/" className="dropdown-notifications-archive">
                          <span className="bx bx-x"></span>
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar"></div>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mb-1">
                          Application has been approved 🚀{" "}
                        </h6>
                        <p className="mb-0">
                          Your ABC project application has been approved.
                        </p>
                        <small className="text-muted">2 days ago</small>
                      </div>
                      <div className="flex-shrink-0 dropdown-notifications-actions">
                        <Link to="/" className="dropdown-notifications-read">
                          <span className="badge badge-dot"></span>
                        </Link>
                        <Link to="/" className="dropdown-notifications-archive">
                          <span className="bx bx-x"></span>
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar">
                          <span className="avatar-initial rounded-circle bg-label-warning">
                            <i className="bx bx-error"></i>
                          </span>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mb-1">CPU is running high</h6>
                        <p className="mb-0">
                          CPU Utilization Percent is currently at 88.63%,
                        </p>
                        <small className="text-muted">5 days ago</small>
                      </div>
                      <div className="flex-shrink-0 dropdown-notifications-actions">
                        <Link to="/" className="dropdown-notifications-read">
                          <span className="badge badge-dot"></span>
                        </Link>
                        <Link to="/" className="dropdown-notifications-archive">
                          <span class="bx bx-x"></span>
                        </Link>
                      </div>
                    </div>
                  </li>
                </ul>
                <div
                  className="ps__rail-x"
                  // style="left: 0px; bottom: 0px;"
                >
                  <div
                    className="ps__thumb-x"
                    tabIndex="0"
                    // style="left: 0px; width: 0px;"
                  ></div>
                </div>
                <div
                  className="ps__rail-y"
                  // style="top: 0px; right: 0px;"
                >
                  <div
                    className="ps__thumb-y"
                    tabIndex="0"
                    // style="top: 0px; height: 0px;"
                  ></div>
                </div>
              </li>
              <li className="dropdown-menu-footer border-top p-3">
                <button className="btn btn-primary text-uppercase w-100">
                  view all notifications
                </button>
              </li>
            </ul>
          </li> */}

          <li class="nav-item dropdown-notifications navbar-dropdown dropdown me-3 me-xl-1">
            <a
              class="nav-link dropdown-toggle hide-arrow"
              href="javascript:void(0);"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              aria-expanded="false"
            >
              <i class="bx bx-bell bx-sm"></i>
              <span class="badge bg-danger rounded-pill badge-notifications">
                5
              </span>
            </a>
            <ul class="dropdown-menu dropdown-menu-end py-0">
              <li class="dropdown-menu-header border-bottom">
                <div class="dropdown-header d-flex align-items-center py-3">
                  <h5 class="text-body mb-0 me-auto">Notification</h5>
                  <a
                    href="javascript:void(0)"
                    class="dropdown-notifications-all text-body"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    aria-label="Mark all as read"
                    data-bs-original-title="Mark all as read"
                  >
                    <i class="bx fs-4 bx-envelope-open"></i>
                  </a>
                </div>
              </li>
              <li class="dropdown-notifications-list scrollable-container ps">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item list-group-item-action dropdown-notifications-item">
                    <div class="d-flex">
                      <div class="flex-shrink-0 me-3">
                        <div class="avatar">
                          {/* <img src="../../assets/img/avatars/1.png" alt="" class="w-px-40 h-auto rounded-circle"> */}
                        </div>
                      </div>
                      <div class="flex-grow-1">
                        <h6 class="mb-1">Congratulation Lettie 🎉</h6>
                        <p class="mb-0">
                          Won the monthly best seller gold badge
                        </p>
                        <small class="text-muted">1h ago</small>
                      </div>
                      <div class="flex-shrink-0 dropdown-notifications-actions">
                        <a
                          href="javascript:void(0)"
                          class="dropdown-notifications-read"
                        >
                          <span class="badge badge-dot"></span>
                        </a>
                        <a
                          href="javascript:void(0)"
                          class="dropdown-notifications-archive"
                        >
                          <span class="bx bx-x"></span>
                        </a>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item list-group-item-action dropdown-notifications-item">
                    <div class="d-flex">
                      <div class="flex-shrink-0 me-3">
                        <div class="avatar">
                          <span class="avatar-initial rounded-circle bg-label-danger">
                            CF
                          </span>
                        </div>
                      </div>
                      <div class="flex-grow-1">
                        <h6 class="mb-1">Charles Franklin</h6>
                        <p class="mb-0">Accepted your connection</p>
                        <small class="text-muted">12hr ago</small>
                      </div>
                      <div class="flex-shrink-0 dropdown-notifications-actions">
                        <a
                          href="javascript:void(0)"
                          class="dropdown-notifications-read"
                        >
                          <span class="badge badge-dot"></span>
                        </a>
                        <a
                          href="javascript:void(0)"
                          class="dropdown-notifications-archive"
                        >
                          <span class="bx bx-x"></span>
                        </a>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                    <div class="d-flex">
                      <div class="flex-shrink-0 me-3">
                        <div class="avatar">
                          {/* <img src="../../assets/img/avatars/2.png" alt="" class="w-px-40 h-auto rounded-circle"> */}
                        </div>
                      </div>
                      <div class="flex-grow-1">
                        <h6 class="mb-1">New Message ✉️</h6>
                        <p class="mb-0">You have new message from Natalie</p>
                        <small class="text-muted">1h ago</small>
                      </div>
                      <div class="flex-shrink-0 dropdown-notifications-actions">
                        <a
                          href="javascript:void(0)"
                          class="dropdown-notifications-read"
                        >
                          <span class="badge badge-dot"></span>
                        </a>
                        <a
                          href="javascript:void(0)"
                          class="dropdown-notifications-archive"
                        >
                          <span class="bx bx-x"></span>
                        </a>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item list-group-item-action dropdown-notifications-item">
                    <div class="d-flex">
                      <div class="flex-shrink-0 me-3">
                        <div class="avatar">
                          <span class="avatar-initial rounded-circle bg-label-success">
                            <i class="bx bx-cart"></i>
                          </span>
                        </div>
                      </div>
                      <div class="flex-grow-1">
                        <h6 class="mb-1">Whoo! You have new order 🛒 </h6>
                        <p class="mb-0">ACME Inc. made new order $1,154</p>
                        <small class="text-muted">1 day ago</small>
                      </div>
                      <div class="flex-shrink-0 dropdown-notifications-actions">
                        <a
                          href="javascript:void(0)"
                          class="dropdown-notifications-read"
                        >
                          <span class="badge badge-dot"></span>
                        </a>
                        <a
                          href="javascript:void(0)"
                          class="dropdown-notifications-archive"
                        >
                          <span class="bx bx-x"></span>
                        </a>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                    <div class="d-flex">
                      <div class="flex-shrink-0 me-3">
                        <div class="avatar">
                          {/* <img src="../../assets/img/avatars/9.png" alt="" class="w-px-40 h-auto rounded-circle"> */}
                        </div>
                      </div>
                      <div class="flex-grow-1">
                        <h6 class="mb-1">Application has been approved 🚀 </h6>
                        <p class="mb-0">
                          Your ABC project application has been approved.
                        </p>
                        <small class="text-muted">2 days ago</small>
                      </div>
                      <div class="flex-shrink-0 dropdown-notifications-actions">
                        <a
                          href="javascript:void(0)"
                          class="dropdown-notifications-read"
                        >
                          <span class="badge badge-dot"></span>
                        </a>
                        <a
                          href="javascript:void(0)"
                          class="dropdown-notifications-archive"
                        >
                          <span class="bx bx-x"></span>
                        </a>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                    <div class="d-flex">
                      <div class="flex-shrink-0 me-3">
                        <div class="avatar">
                          <span class="avatar-initial rounded-circle bg-label-success">
                            <i class="bx bx-pie-chart-alt"></i>
                          </span>
                        </div>
                      </div>
                      <div class="flex-grow-1">
                        <h6 class="mb-1">Monthly report is generated</h6>
                        <p class="mb-0">
                          July monthly financial report is generated{" "}
                        </p>
                        <small class="text-muted">3 days ago</small>
                      </div>
                      <div class="flex-shrink-0 dropdown-notifications-actions">
                        <a
                          href="javascript:void(0)"
                          class="dropdown-notifications-read"
                        >
                          <span class="badge badge-dot"></span>
                        </a>
                        <a
                          href="javascript:void(0)"
                          class="dropdown-notifications-archive"
                        >
                          <span class="bx bx-x"></span>
                        </a>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                    <div class="d-flex">
                      <div class="flex-shrink-0 me-3">
                        <div class="avatar">
                          {/* <img src="../../assets/img/avatars/5.png" alt="" class="w-px-40 h-auto rounded-circle"> */}
                        </div>
                      </div>
                      <div class="flex-grow-1">
                        <h6 class="mb-1">Send connection request</h6>
                        <p class="mb-0">Peter sent you connection request</p>
                        <small class="text-muted">4 days ago</small>
                      </div>
                      <div class="flex-shrink-0 dropdown-notifications-actions">
                        <a
                          href="javascript:void(0)"
                          class="dropdown-notifications-read"
                        >
                          <span class="badge badge-dot"></span>
                        </a>
                        <a
                          href="javascript:void(0)"
                          class="dropdown-notifications-archive"
                        >
                          <span class="bx bx-x"></span>
                        </a>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item list-group-item-action dropdown-notifications-item">
                    <div class="d-flex">
                      <div class="flex-shrink-0 me-3">
                        <div class="avatar">
                          {/* <img src="../../assets/img/avatars/6.png" alt="" class="w-px-40 h-auto rounded-circle"> */}
                        </div>
                      </div>
                      <div class="flex-grow-1">
                        <h6 class="mb-1">New message from Jane</h6>
                        <p class="mb-0">Your have new message from Jane</p>
                        <small class="text-muted">5 days ago</small>
                      </div>
                      <div class="flex-shrink-0 dropdown-notifications-actions">
                        <a
                          href="javascript:void(0)"
                          class="dropdown-notifications-read"
                        >
                          <span class="badge badge-dot"></span>
                        </a>
                        <a
                          href="javascript:void(0)"
                          class="dropdown-notifications-archive"
                        >
                          <span class="bx bx-x"></span>
                        </a>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                    <div class="d-flex">
                      <div class="flex-shrink-0 me-3">
                        <div class="avatar">
                          <span class="avatar-initial rounded-circle bg-label-warning">
                            <i class="bx bx-error"></i>
                          </span>
                        </div>
                      </div>
                      <div class="flex-grow-1">
                        <h6 class="mb-1">CPU is running high</h6>
                        <p class="mb-0">
                          CPU Utilization Percent is currently at 88.63%,
                        </p>
                        <small class="text-muted">5 days ago</small>
                      </div>
                      <div class="flex-shrink-0 dropdown-notifications-actions">
                        <a
                          href="javascript:void(0)"
                          class="dropdown-notifications-read"
                        >
                          <span class="badge badge-dot"></span>
                        </a>
                        <a
                          href="javascript:void(0)"
                          class="dropdown-notifications-archive"
                        >
                          <span class="bx bx-x"></span>
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
                <div class="ps__rail-x">
                  <div class="ps__thumb-x" tabindex="0"></div>
                </div>
                <div class="ps__rail-y">
                  <div class="ps__thumb-y" tabindex="0"></div>
                </div>
              </li>
              <li class="dropdown-menu-footer border-top p-3">
                <button class="btn btn-primary text-uppercase w-100">
                  view all notifications
                </button>
              </li>
            </ul>
          </li>

          <li className="nav-item navbar-dropdown dropdown-user dropdown">
            <Link
              to="#"
              className="nav-link dropdown-toggle hide-arrow"
              onClick={(e) => e.preventDefault()}
              data-bs-toggle="dropdown"
            >
              <i className="bx bx-user black-icon"></i>
            </Link>
            <ul className="dropdown-menu dropdown-menu-end">
              <li>
                <Link className="dropdown-item" to="#">
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <div className="avatar avatar-online">
                        <img
                          src="../assets/img/avatars/1.png"
                          alt=""
                          className="w-px-40 h-auto rounded-circle"
                        />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <span className="fw-semibold d-block">
                        {/* {decrypt(Cookies.get("email"))} */}
                        {Cookies.get("us") ? decrypt(Cookies.get("us")) : ""}
                      </span>
                      <small className="text-muted">
                        {/* {Cookies.get("roleName")} */}
                        {Cookies.get("roleName")
                          ? decrypt(Cookies.get("roleName"))
                          : ""}
                      </small>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <div className="dropdown-divider"></div>
              </li>
              <li>
                <Link className="dropdown-item" to="/account/profile">
                  <i className="bx bx-user me-2"></i>
                  <span className="align-middle">My Profile</span>
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="#">
                  <i className="bx bx-cog me-2"></i>
                  <span className="align-middle">Settings</span>
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="#">
                  <span className="d-flex align-items-center align-middle">
                    <i className="flex-shrink-0 bx bx-credit-card me-2"></i>
                    <span className="flex-grow-1 align-middle">Billing</span>
                    <span className="flex-shrink-0 badge badge-center rounded-pill bg-danger w-px-20 h-px-20">
                      4
                    </span>
                  </span>
                </Link>
              </li>
              <li>
                <div className="dropdown-divider"></div>
              </li>
              <li>
                <Link className="dropdown-item" onClick={logout}>
                  <i className="bx bx-power-off me-2"></i>
                  <span className="align-middle">Log Out</span>
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;
