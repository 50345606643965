// src/graphql/categoryQueries.js
import { gql } from "@apollo/client";

// Queries
export const GET_CATEGORIES = gql`
  query Categories($limit: Int!, $offset: Int!) {
    categories(limit: $limit, offset: $offset) {
      id
      name
      description
      parentCategory {
        id
        name
      }
      status
    }
  }
`;

export const GET_CATEGORY = gql`
  query Category($id: ID!) {
    category(id: $id) {
      id
      name
      description
      parentCategory {
        id
        name
      }
      status
    }
  }
`;

export const GET_CATEGORIES_COUNT = gql`
  query CategoriesCount {
    categoriesCount
  }
`;

// Mutations
// Mutations
export const ADD_CATEGORY = gql`
  mutation AddCategory(
    $name: String!
    $description: String!
    $parentCategory: ID
    $status: String!
  ) {
    addCategory(
      name: $name
      description: $description
      parentCategory: $parentCategory
      status: $status
    ) {
      id
      name
      description
      parentCategory {
        id
        name
      }
      status
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation UpdateCategory(
    $id: ID!
    $name: String!
    $description: String!
    $parentCategory: ID
    $status: String!
  ) {
    updateCategory(
      id: $id
      name: $name
      description: $description
      parentCategory: $parentCategory
      status: $status
    ) {
      id
      name
      description
      parentCategory {
        id
        name
      }
      status
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation DeleteCategory($id: ID!) {
    deleteCategory(id: $id) {
      id
    }
  }
`;
