import Cookies from "js-cookie";

export const logout = () => {
  Cookies.remove("token");
  Cookies.remove("role");
  Cookies.remove("roleName");
  Cookies.remove("permissions");
  Cookies.remove("us");

  window.location.href = "/login";
};
