import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";
import NotFound from "./components/NotFound";
import ProtectedRoute from "./components/ProtectedRoute";
import routesWithLayout from "./routes/routesWithLayout";
import routesWithoutLayout from "./routes/routesWithoutLayout";

const AppLayout = ({ children }) => {
  const location = useLocation();
  const pathsWithoutLayout = routesWithoutLayout.map((route) => route.path);
  const shouldShowLayout = !pathsWithoutLayout.includes(location.pathname);

  return (
    <>
      {shouldShowLayout ? (
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">
            <Sidebar />
            <div className="layout-page">
              <Header />
              {children}
              <Footer />
            </div>
          </div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

const App = () => {
  return (
    <Router>
      <AppLayout>
        <Routes>
          {routesWithLayout.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <ProtectedRoute
                  element={route.element}
                  requiredPermissions={route.requiredPermissions}
                />
              }
            />
          ))}
          {routesWithoutLayout.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AppLayout>
    </Router>
  );
};

export default App;
