import React, { useState, useRef } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Breadcrumbs from "../Breadcrumbs"; // Adjust the path as per your project structure

import profilePic from "../../img/1.png";
import banner from "../../img/s.png";

import "../../styles/page-profile.css";

import { getUserStatusNameFromID } from "../../utils/utility";

// GraphQL queries and mutations (adjust the path as needed)
import {
  // GET_ROLES,
  GET_USER,
  // UPDATE_USER,
} from "../../graphql/queries";

const UserDetail = () => {
  const { id } = useParams();

  const {
    loading: userLoading,
    error: userError,
    data: userData,
    refetch,
  } = useQuery(GET_USER, {
    variables: { id },
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const toastRef = useRef(null);

  const showToast = () => {
    const toast = new window.bootstrap.Toast(toastRef.current);
    toast.show();
  };

  if (userError) return <p>Error loading user: {userError.message}</p>;

  if (userLoading) return <p>Loading...</p>;

  const user = userData ? userData.user : null;

  if (!user) return <p>No user data available</p>;

  console.log("test--------------------", user);

  return (
    <div className="content-wrapper">
      {/* Content */}
      <div className="container-xxl flex-grow-1 container-p-y">
        {/* <h4 className="py-3 mb-4">
          <span className="text-muted fw-light">User Profile /</span> Profile
        </h4> */}
        <Breadcrumbs
          items={[
            { name: "Users", url: "/users" },
            { name: user.username, url: "" },
          ]}
        />
        {/* Header */}
        <div className="row">
          <div className="col-12">
            <div className="card mb-4">
              <div className="user-profile-header-banner">
                <img src={banner} alt="Banner" className="rounded-top" />
              </div>
              <div className="user-profile-header d-flex flex-column flex-sm-row text-sm-start text-center mb-4">
                <div className="flex-shrink-0 mt-n2 mx-sm-0 mx-auto">
                  <img
                    src={profilePic}
                    alt="user"
                    className="d-block h-auto ms-0 ms-sm-4 rounded user-profile-img"
                  />
                </div>
                <div className="flex-grow-1 mt-3 mt-sm-5">
                  <div className="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-4 flex-md-row flex-column gap-4">
                    <div className="user-profile-info">
                      <h4>{user.name}</h4>
                      <ul className="list-inline mb-0 d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center gap-2">
                        <li className="list-inline-item fw-medium">
                          <i className="bx bx-pen"></i> {user.role.name}
                        </li>
                        <li className="list-inline-item fw-medium">
                          <i className="bx bx-map"></i>
                          {/* {user.location} */}
                        </li>
                        <li className="list-inline-item fw-medium">
                          <i className="bx bx-calendar-alt"></i> Joined{" "}
                          {user.joinedDate}
                        </li>
                      </ul>
                    </div>
                    <a
                      href="javascript:void(0)"
                      className="btn btn-primary text-nowrap"
                    >
                      <i className="bx bx-user-check me-1"></i>Connected
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*/ Header */}

        {/* Navbar pills */}
        <div className="row">
          <div className="col-md-12">
            <ul className="nav nav-pills flex-column flex-sm-row mb-4">
              <li className="nav-item">
                <a className="nav-link active" href="javascript:void(0);">
                  <i className="bx bx-user me-1"></i> Profile
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="pages-profile-teams.html">
                  <i className="bx bx-group me-1"></i> Teams
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="pages-profile-projects.html">
                  <i className="bx bx-grid-alt me-1"></i> Projects
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="pages-profile-connections.html">
                  <i className="bx bx-link-alt me-1"></i> Connections
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/*/ Navbar pills */}

        {/* User Profile Content */}
        <div className="row">
          <div className="col-xl-4 col-lg-5 col-md-5">
            {/* About User */}
            <div className="card mb-4">
              <div className="card-body">
                <small className="text-muted text-uppercase">About</small>
                <ul className="list-unstyled mb-4 mt-3">
                  <li className="d-flex align-items-center mb-3">
                    <i className="bx bx-user"></i>
                    <span className="fw-medium mx-2">User Name:</span>{" "}
                    <span>{user.username}</span>
                  </li>
                  <li className="d-flex align-items-center mb-3">
                    <i className="bx bx-check"></i>
                    <span className="fw-medium mx-2">Status:</span>{" "}
                    <span>{getUserStatusNameFromID(user.status)}</span>
                  </li>
                  <li className="d-flex align-items-center mb-3">
                    <i className="bx bx-star"></i>
                    <span className="fw-medium mx-2">Role:</span>{" "}
                    <span>{user.role.name}</span>
                  </li>
                </ul>
                <small className="text-muted text-uppercase">Contacts</small>
                <ul className="list-unstyled mb-4 mt-3">
                  <li className="d-flex align-items-center mb-3">
                    <i className="bx bx-envelope"></i>
                    <span className="fw-medium mx-2">Email:</span>{" "}
                    <span>{user.email}</span>
                  </li>
                </ul>
              </div>
            </div>
            {/*/ About User */}

            {/* Profile Overview */}
            <div className="card mb-4">
              <div className="card-body">
                <small className="text-muted text-uppercase">Overview</small>
                <ul className="list-unstyled mt-3 mb-0">
                  <li className="d-flex align-items-center mb-3">
                    <i className="bx bx-check"></i>
                    <span className="fw-medium mx-2">Task Compiled:</span>
                    <span>{user.taskCompiled}</span>
                  </li>
                  <li className="d-flex align-items-center mb-3">
                    <i className="bx bx-customize"></i>
                    <span className="fw-medium mx-2">Projects Compiled:</span>
                    <span>{user.projectsCompiled}</span>
                  </li>
                  <li className="d-flex align-items-center">
                    <i className="bx bx-user"></i>
                    <span className="fw-medium mx-2">Connections:</span>
                    <span>{user.connections}</span>
                  </li>
                </ul>
              </div>
            </div>
            {/*/ Profile Overview */}
          </div>

          <div className="col-xl-8 col-lg-7 col-md-7">
            {/*/ Activity Timeline */}

            {/* Projects table */}
            <div className="card mb-4">
              <h5 className="card-header">Articles List</h5>
              <div className="table-responsive mb-3">
                <div
                  id="DataTables_Table_0_wrapper"
                  className="dataTables wrapper dt-bootstrap5 no-footer"
                >
                  <div className="d-flex justify-content-between align-items-center flex-column flex-sm-row mx-4 row">
                    <div className="col-sm-4 col-12 d-flex align-items-center justify-content-sm-start justify-content-center">
                      <div
                        className="dataTables_length"
                        id="DataTables_Table_0_length"
                      >
                        <label>
                          Show
                          <select
                            name="DataTables_Table_0_length"
                            aria-controls="DataTables_Table_0"
                            className="form-select"
                          >
                            <option value="7">7</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="75">75</option>
                            <option value="100">100</option>
                          </select>
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-8 col-12 d-flex align-items-center justify-content-sm-end justify-content-center">
                      <div
                        id="DataTables_Table_0_filter"
                        className="dataTables_filter"
                      >
                        <label>
                          Search:
                          <input
                            type="search"
                            className="form-control"
                            placeholder="Search Project"
                            aria-controls="DataTables_Table_0"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <table
                    className="table datatable-project dataTable no-footer dtr-column"
                    id="DataTables_Table_0"
                    aria-describedby="DataTables_Table_0_info"
                    style={{ width: "765px" }}
                  >
                    <thead className="table-light">
                      <tr>
                        <th
                          className="control sorting_disabled dtr-hidden"
                          rowspan="1"
                          colspan="1"
                          style={{ width: "0px", display: "none" }}
                          aria-label=""
                        ></th>
                        <th
                          className="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all"
                          rowspan="1"
                          colspan="1"
                          style={{ width: "18px" }}
                          data-col="1"
                          aria-label=""
                        >
                          <input type="checkbox" className="form-check-input" />
                        </th>
                        <th
                          className="sorting sorting_desc"
                          tabIndex="0"
                          aria-controls="DataTables_Table_0"
                          rowspan="1"
                          colspan="1"
                          style={{ width: "258px" }}
                          aria-label="Project: activate to sort column ascending"
                          aria-sort="descending"
                        >
                          Project
                        </th>
                        <th
                          className="text-nowrap sorting_disabled"
                          rowspan="1"
                          colspan="1"
                          style={{ width: "106px" }}
                          aria-label="Total Task"
                        >
                          Total Task
                        </th>
                        <th
                          className="sorting"
                          tabIndex="0"
                          aria-controls="DataTables_Table_0"
                          rowspan="1"
                          colspan="1"
                          style={{ width: "97px" }}
                          aria-label="Progress: activate to sort column ascending"
                        >
                          Progress
                        </th>
                        <th
                          className="sorting_disabled"
                          rowspan="1"
                          colspan="1"
                          style={{ width: "74px" }}
                          aria-label="Hours"
                        >
                          Hours
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {user.projects.map((project, index) => (
                        <tr
                          key={index}
                          className={index % 2 === 0 ? "even" : "odd"}
                        >
                          <td
                            className="control"
                            tabindex="0"
                            style={{ display: "none" }}
                          ></td>
                          <td className="dt-checkboxes-cell">
                            <input
                              type="checkbox"
                              className="dt-checkboxes form-check-input"
                            />
                          </td>
                          <td className="sorting_1">
                            <div className="d-flex justify-content-left align-items-center">
                              <div className="avatar-wrapper">
                                <div className="avatar avatar-sm me-3">
                                  <img
                                    src={project.image}
                                    alt="Project Image"
                                    className="rounded-circle"
                                  />
                                </div>
                              </div>
                              <div className="d-flex flex-column">
                                <span className="text-truncate fw-medium">
                                  {project.name}
                                </span>
                                <small className="text-muted">
                                  {project.description}
                                </small>
                              </div>
                            </div>
                          </td>
                          <td>{project.totalTasks}</td>
                          <td>
                            <div className="d-flex flex-column">
                              <small className="mb-1">
                                {project.progress}%
                              </small>
                              <div
                                className="progress w-100 me-3"
                                style={{ height: "6px" }}
                              >
                                <div
                                  className={`progress-bar ${
                                    project.progress > 50
                                      ? "bg-success"
                                      : "bg-warning"
                                  }`}
                                  style={{ width: `${project.progress}%` }}
                                  aria-valuenow={project.progress}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            </div>
                          </td>
                          <td>{project.hours}</td>
                        </tr>
                      ))} */}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-between mx-4 row">
                    <div className="col-sm-12 col-md-6">
                      <div
                        className="dataTables_info"
                        id="DataTables_Table_0_info"
                        role="status"
                        aria-live="polite"
                      >
                        {/* Showing 1 to {user.projects.length} of{" "}
                        {user.totalProjects} entries */}
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="DataTables_Table_0_paginate"
                      >
                        <ul className="pagination">
                          <li
                            className="paginate_button page-item previous disabled"
                            id="DataTables_Table_0_previous"
                          >
                            <a
                              aria-controls="DataTables_Table_0"
                              aria-disabled="true"
                              role="link"
                              data-dt-idx="previous"
                              tabIndex="-1"
                              className="page-link"
                            >
                              Previous
                            </a>
                          </li>
                          <li className="paginate_button page-item active">
                            <a
                              href="#"
                              aria-controls="DataTables_Table_0"
                              role="link"
                              aria-current="page"
                              data-dt-idx="0"
                              tabIndex="0"
                              className="page-link"
                            >
                              1
                            </a>
                          </li>
                          <li className="paginate_button page-item">
                            <a
                              href="#"
                              aria-controls="DataTables_Table_0"
                              role="link"
                              data-dt-idx="1"
                              tabIndex="0"
                              className="page-link"
                            >
                              2
                            </a>
                          </li>
                          <li
                            className="paginate_button page-item next"
                            id="DataTables_Table_0_next"
                          >
                            <a
                              href="#"
                              aria-controls="DataTables_Table_0"
                              role="link"
                              data-dt-idx="next"
                              tabIndex="0"
                              className="page-link"
                            >
                              Next
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "1%" }}></div>
                </div>
              </div>
            </div>
            {/*/ Projects table */}
          </div>
        </div>
        {/*/ User Profile Content */}
      </div>
      {/* / Content */}

      <div className="content-backdrop fade"></div>
    </div>
  );
};

export default UserDetail;
