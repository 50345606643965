import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import Breadcrumbs from "../Breadcrumbs";
import {
  GET_CATEGORIES,
  GET_CATEGORIES_COUNT,
  ADD_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
} from "../../graphql/queries/categoryQueries";

const Categories = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoriesPerPage, setCategoriesPerPage] = useState(10);

  const { loading, error, data, refetch } = useQuery(GET_CATEGORIES, {
    variables: {
      limit: categoriesPerPage,
      offset: (currentPage - 1) * categoriesPerPage,
    },
  });

  const { data: countData } = useQuery(GET_CATEGORIES_COUNT);

  const [addCategory] = useMutation(ADD_CATEGORY, {
    onCompleted: () => {
      setSuccessMessage("Category added successfully!");
      refetch();
      closeModal("addCategoryModal");
    },
    onError: (error) => {
      console.error("Error adding category:", error);
    },
  });

  const [updateCategory] = useMutation(UPDATE_CATEGORY, {
    onCompleted: () => {
      setSuccessMessage("Category updated successfully!");
      refetch();
      closeModal("editCategoryModal");
    },
    onError: (error) => {
      console.error("Error updating category:", error);
    },
  });

  const [deleteCategory] = useMutation(DELETE_CATEGORY, {
    onCompleted: () => {
      setSuccessMessage("Category deleted successfully!");
      refetch();
      closeModal("deleteCategoryModal");
    },
    onError: (error) => {
      console.error("Error deleting category:", error);
    },
  });

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    if (currentPage * categoriesPerPage < totalCategories) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleAddCategory = (e) => {
    e.preventDefault();
    const {
      modalCategoryName,
      modalCategoryDescription,
      modalCategoryParent,
      modalCategoryStatus,
    } = e.target.elements;
    addCategory({
      variables: {
        name: modalCategoryName.value,
        description: modalCategoryDescription.value,
        parentCategory: modalCategoryParent.value || null,
        status: modalCategoryStatus.value,
      },
    }).then(() => {
      // Clear the form inputs after successful addition
      modalCategoryName.value = "";
      modalCategoryDescription.value = "";
      modalCategoryParent.value = "";
      modalCategoryStatus.value = "active";
    });
  };

  const handleEditCategory = (e) => {
    e.preventDefault();
    const {
      editCategoryName,
      editCategoryDescription,
      editCategoryParent,
      editCategoryStatus,
    } = e.target.elements;
    updateCategory({
      variables: {
        id: selectedCategory.id,
        name: editCategoryName.value,
        description: editCategoryDescription.value,
        parentCategory: editCategoryParent.value || null,
        status: editCategoryStatus.value,
      },
    });
  };

  const handleDeleteCategory = () => {
    deleteCategory({ variables: { id: selectedCategory.id } });
  };

  const closeModal = (modalId) => {
    const modal = window.bootstrap.Modal.getInstance(
      document.getElementById(modalId)
    );
    modal.hide();
    document.body.classList.remove("modal-open");
    const backdrop = document.querySelector(".modal-backdrop");
    if (backdrop) backdrop.remove();
  };

  const handleCategoriesPerPageChange = (event) => {
    const newCategoriesPerPage = parseInt(event.target.value, 10);
    setCategoriesPerPage(newCategoriesPerPage);
    setCurrentPage(1);
    refetch({
      limit: newCategoriesPerPage,
      offset: 0,
    });
  };

  useEffect(() => {
    // Initialize modals with 'static' backdrop and 'keyboard' option set to false
    const modals = [
      "addCategoryModal",
      "editCategoryModal",
      "deleteCategoryModal",
    ];
    modals.forEach((modalId) => {
      const modalElement = document.getElementById(modalId);
      if (modalElement) {
        new window.bootstrap.Modal(modalElement, {
          backdrop: "static",
          keyboard: false,
        });
      }
    });
  }, []);

  const filteredCategories = data
    ? data.categories.filter((category) =>
        category.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  const totalCategories = countData ? countData.categoriesCount : 0;

  return (
    <div className="content-wrapper">
      <div className="container-xxl flex-grow-1 container-p-y">
        <Breadcrumbs items={[{ name: "Categories", url: "/categories" }]} />
        <div className="row">
          <div className="col-lg-12 mb-4 order-0">
            <div className="card">
              <h5 className="card-header">Categories</h5>
              {successMessage && (
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              )}
              <div className="table-responsive text-nowrap">
                <div className="row mx-1">
                  <div className="col-sm-12 col-md-3">
                    <div
                      className="dataTables_length"
                      id="DataTables_Table_0_length"
                    >
                      <label>
                        <select
                          className="form-select"
                          onChange={handleCategoriesPerPageChange}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-9">
                    <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap me-1">
                      <div className="me-3">
                        <div
                          id="DataTables_Table_0_filter"
                          className="dataTables_filter"
                        >
                          <label>
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Search by category name"
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="dt-buttons btn-group flex-wrap">
                        <button
                          className="btn add-new btn-primary mb-3 mb-md-0"
                          tabIndex="0"
                          aria-controls="DataTables_Table_0"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#addCategoryModal"
                        >
                          <span>Add Category</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {loading && <p>Loading...</p>}
                {error && <p>Error: {error.message}</p>}
                {!loading && !error && (
                  <>
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Name</th>
                          <th>Description</th>
                          <th>Parent Category</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredCategories.map((category, index) => (
                          <tr key={category.id}>
                            <td>
                              {(currentPage - 1) * categoriesPerPage +
                                index +
                                1}
                            </td>
                            <td>{category.name}</td>
                            <td>{category.description}</td>
                            <td>
                              {category.parentCategory
                                ? category.parentCategory.name
                                : "None"}
                            </td>
                            <td>{category.status}</td>
                            <td>
                              <span className="text-nowrap">
                                <button
                                  className="btn btn-sm btn-icon me-2"
                                  data-bs-target="#editCategoryModal"
                                  data-bs-toggle="modal"
                                  data-bs-dismiss="modal"
                                  onClick={() => setSelectedCategory(category)}
                                >
                                  <i className="bx bx-edit"></i>
                                </button>
                                <button
                                  className="btn btn-sm btn-icon delete-record"
                                  onClick={() => setSelectedCategory(category)}
                                  data-bs-target="#deleteCategoryModal"
                                  data-bs-toggle="modal"
                                >
                                  <i className="bx bx-trash"></i>
                                </button>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div
                      className="pagination d-flex justify-content-end mt-3"
                      style={{ margin: "20px", marginRight: "20px" }}
                    >
                      <button
                        className="btn btn-primary me-2"
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={handleNextPage}
                        disabled={
                          currentPage * categoriesPerPage >= totalCategories
                        }
                      >
                        Next
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="addCategoryModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-simple">
          <div className="modal-content p-3 p-md-5">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close btn-pinned"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="text-center mb-4">
                <h3>Add New Category</h3>
                <p>Categories you may use and assign to your articles.</p>
              </div>
              <form
                id="addCategoryForm"
                className="row"
                onSubmit={handleAddCategory}
              >
                <div className="col-12 mb-3">
                  <label className="form-label" htmlFor="modalCategoryName">
                    Category Name
                  </label>
                  <input
                    type="text"
                    id="modalCategoryName"
                    name="modalCategoryName"
                    className="form-control"
                    placeholder="Category Name"
                    required
                  />
                </div>
                <div className="col-12 mb-3">
                  <label
                    className="form-label"
                    htmlFor="modalCategoryDescription"
                  >
                    Category Description
                  </label>
                  <input
                    type="text"
                    id="modalCategoryDescription"
                    name="modalCategoryDescription"
                    className="form-control"
                    placeholder="Category Description"
                    required
                  />
                </div>
                <div className="col-12 mb-3">
                  <label className="form-label" htmlFor="modalCategoryParent">
                    Parent Category
                  </label>
                  <select
                    id="modalCategoryParent"
                    name="modalCategoryParent"
                    className="form-select"
                  >
                    <option value="">None</option>
                    {data &&
                      data.categories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-12 mb-3">
                  <label className="form-label" htmlFor="modalCategoryStatus">
                    Status
                  </label>
                  <select
                    id="modalCategoryStatus"
                    name="modalCategoryStatus"
                    className="form-select"
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
                <div className="col-12 text-center demo-vertical-spacing">
                  <button
                    type="submit"
                    className="btn btn-primary me-sm-3 me-1"
                  >
                    Create Category
                  </button>
                  <button
                    type="reset"
                    className="btn btn-label-secondary"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Discard
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="editCategoryModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-simple">
          <div className="modal-content p-3 p-md-5">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="text-center mb-4">
                <h3>Edit Category</h3>
              </div>
              <div className="alert alert-warning" role="alert">
                <h6 className="alert-heading mb-2">Warning</h6>
                <p className="mb-0">
                  By editing the category name, you might affect existing
                  articles. Please ensure you're absolutely certain before
                  proceeding.
                </p>
              </div>
              <form
                id="editCategoryForm"
                className="row"
                onSubmit={handleEditCategory}
              >
                <div className="col-sm-12 mb-3">
                  <label className="form-label" htmlFor="editCategoryName">
                    Category Name
                  </label>
                  <input
                    type="text"
                    id="editCategoryName"
                    name="editCategoryName"
                    className="form-control"
                    placeholder="Category Name"
                    required
                    defaultValue={selectedCategory ? selectedCategory.name : ""}
                  />
                </div>
                <div className="col-sm-12 mb-3">
                  <label
                    className="form-label"
                    htmlFor="editCategoryDescription"
                  >
                    Category Description
                  </label>
                  <input
                    type="text"
                    id="editCategoryDescription"
                    name="editCategoryDescription"
                    className="form-control"
                    placeholder="Category Description"
                    required
                    defaultValue={
                      selectedCategory ? selectedCategory.description : ""
                    }
                  />
                </div>
                <div className="col-sm-12 mb-3">
                  <label className="form-label" htmlFor="editCategoryParent">
                    Parent Category
                  </label>
                  <select
                    id="editCategoryParent"
                    name="editCategoryParent"
                    className="form-select"
                    defaultValue={
                      selectedCategory && selectedCategory.parentCategory
                        ? selectedCategory.parentCategory._id
                        : ""
                    }
                  >
                    <option value="">None</option>
                    {data &&
                      data.categories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-12 mb-3">
                  <label className="form-label" htmlFor="editCategoryStatus">
                    Status
                  </label>
                  <select
                    id="editCategoryStatus"
                    name="editCategoryStatus"
                    className="form-select"
                    defaultValue={
                      selectedCategory && selectedCategory.status
                        ? selectedCategory.status
                        : "active"
                    }
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
                <div className="col-12 text-center demo-vertical-spacing">
                  <button
                    type="submit"
                    className="btn btn-primary me-sm-3 me-1"
                  >
                    Update Category
                  </button>
                  <button
                    type="reset"
                    className="btn btn-label-secondary"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Discard
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="deleteCategoryModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-simple">
          <div className="modal-content p-3 p-md-5">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="text-center mb-4">
                <h3>Delete Category</h3>
              </div>
              <div className="alert alert-warning" role="alert">
                <h6 className="alert-heading mb-2">Warning</h6>
                <p className="mb-0">
                  Are you sure you want to delete this category? This action
                  cannot be undone.
                </p>
              </div>
              <div className="col-12 text-center demo-vertical-spacing">
                <button
                  type="button"
                  className="btn btn-danger me-sm-3 me-1"
                  onClick={handleDeleteCategory}
                >
                  Delete Category
                </button>
                <button
                  type="button"
                  className="btn btn-label-secondary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
