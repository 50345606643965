// src/graphql/roleMutations.js
import { gql } from "@apollo/client";

export const ADD_ROLE = gql`
  mutation AddRole($name: String!, $description: String!, $permissions: [ID!]) {
    addRole(name: $name, description: $description, permissions: $permissions) {
      id
      name
      description
      permissions {
        id
        name
      }
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation UpdateRole(
    $id: ID!
    $name: String!
    $description: String!
    $permissions: [ID!]
  ) {
    updateRole(
      id: $id
      name: $name
      description: $description
      permissions: $permissions
    ) {
      id
      name
      description
      permissions {
        id
        name
      }
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation DeleteRole($id: ID!) {
    deleteRole(id: $id) {
      id
    }
  }
`;
