import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { formatDate } from "../../utils/dateHelpers";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Breadcrumbs from "../Breadcrumbs";
import {
  GET_USERS,
  GET_USERS_COUNT,
  UPDATE_USER_STATUS,
  GET_ALL_ROLES,
  REGISTER_USER,
  UPDATE_USER,
} from "../../graphql/queries";

const usersPerPageCount = parseInt(process.env.REACT_APP_USERS_PER_PAGE);

const UsersList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [usersPerPage, setUsersPerPage] = useState(usersPerPageCount);
  const [mode, setMode] = useState("add"); // "add" or "edit"
  const [initialValues, setInitialValues] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    roleName: "",
  });

  const { loading, error, data, refetch } = useQuery(GET_USERS, {
    variables: {
      limit: usersPerPage,
      offset: (currentPage - 1) * usersPerPage,
    },
  });

  const { data: countData } = useQuery(GET_USERS_COUNT);
  const {
    data: rolesData,
    // loading: rolesLoading
  } = useQuery(GET_ALL_ROLES);

  const [updateUserStatus] = useMutation(UPDATE_USER_STATUS, {
    onCompleted: () => {
      setSuccessMessage("User status updated successfully!");
      refetch();
    },
    onError: (error) => {
      console.error("Error updating user status:", error);
    },
  });

  const [registerUser] = useMutation(REGISTER_USER, {
    onCompleted: () => {
      setSuccessMessage("User added successfully!");
      refetch();
      closeModal("addUserModal");
    },
    onError: (error) => {
      console.error("Error adding user:", error);
    },
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      setSuccessMessage("User updated successfully!");
      refetch();
      closeModal("addUserModal");
    },
    onError: (error) => {
      console.error("Error updating user:", error);
    },
  });

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    if (currentPage * usersPerPage < totalUsers) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleStatusOpenModal = (user) => {
    setSelectedUser(user);
    const modal = new window.bootstrap.Modal(
      document.getElementById("userModal")
    );
    modal.show();
  };

  const handleOpenModal = (user = null) => {
    if (user) {
      setMode("edit");
      setInitialValues({
        username: user.username,
        email: user.email,
        password: "",
        confirmPassword: "",
        roleName: user.role.name,
      });
      setSelectedUser(user);
    } else {
      setMode("add");
      setInitialValues({
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
        roleName: "",
      });
    }
    const modal = new window.bootstrap.Modal(
      document.getElementById("addUserModal")
    );
    modal.show();
  };

  const handleConfirmation = () => {
    const newStatus = selectedUser.status === 1 ? 0 : 1;
    updateUserStatus({ variables: { id: selectedUser.id, status: newStatus } });
    const modal = window.bootstrap.Modal.getInstance(
      document.getElementById("userModal")
    );
    modal.hide();
  };

  const handlePermissionsPerPageChange = (event) => {
    const newUsersPerPage = parseInt(event.target.value, 10);
    setUsersPerPage(newUsersPerPage);
    setCurrentPage(1);
    refetch({
      limit: newUsersPerPage,
      offset: 0,
    });
  };

  const closeModal = (modalId) => {
    const modal = window.bootstrap.Modal.getInstance(
      document.getElementById(modalId)
    );
    modal.hide();
    document.body.classList.remove("modal-open");
    const backdrop = document.querySelector(".modal-backdrop");
    if (backdrop) backdrop.remove();
  };

  const filteredUsers = data
    ? data.users.filter(
        (user) =>
          user.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user.email.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  const totalUsers = countData ? countData.usersCount : 0;

  return (
    <div className="content-wrapper">
      <div className="container-xxl flex-grow-1 container-p-y">
        <Breadcrumbs items={[{ name: "Users", url: "/users" }]} />
        <div className="row">
          <div className="col-lg-12 mb-4 order-0">
            <div className="card">
              <h5 className="card-header">Users</h5>
              {successMessage && (
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              )}
              <div className="table-responsive text-nowrap">
                <div className="row mx-1">
                  <div className="col-sm-12 col-md-3">
                    <div
                      className="dataTables_length"
                      id="DataTables_Table_0_length"
                    >
                      <label>
                        <select
                          name="DataTables_Table_0_length"
                          aria-controls="DataTables_Table_0"
                          className="form-select"
                          onChange={handlePermissionsPerPageChange}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-9">
                    <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap me-1">
                      <div className="me-3">
                        <div
                          id="DataTables_Table_0_filter"
                          className="dataTables_filter"
                        >
                          <label>
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Search by user name"
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="dt-buttons btn-group flex-wrap">
                        <button
                          className="btn add-new btn-primary mb-3 mb-md-0"
                          tabIndex="0"
                          aria-controls="DataTables_Table_0"
                          type="button"
                          onClick={() => handleOpenModal()}
                        >
                          <span>Add User</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {loading && <p>Loading...</p>}
                {error && <p>Error: {error.message}</p>}
                {!loading && !error && (
                  <>
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Username</th>
                          <th>Email</th>
                          <th>Role</th>
                          <th>Created At</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredUsers.map((user, index) => (
                          <tr className={`user-${user.id}`} key={user.id}>
                            <td>
                              {(currentPage - 1) * usersPerPage + index + 1}
                            </td>
                            <td>
                              <Link
                                // targ et="_blank"
                                to={`/user/${user.id}`}
                                className="menu-link"
                              >
                                {user.username}
                              </Link>
                            </td>
                            <td>{user.email}</td>
                            <td>{user.role.name}</td>
                            <td>{formatDate(user.createdAt)}</td>
                            <td>
                              <span
                                className={
                                  user.status === 1
                                    ? "badge bg-label-primary me-1"
                                    : "badge bg-label-warning me-1"
                                }
                              >
                                {user.status === 1 ? "Active" : "Inactive"}
                              </span>
                            </td>
                            <td>
                              <div className="d-inline-block">
                                <Link
                                  to="/"
                                  className="btn btn-sm btn-icon dropdown-toggle hide-arrow"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="bx bx-dots-vertical-rounded"></i>
                                </Link>
                                <div className="dropdown-menu dropdown-menu-end m-0">
                                  <Link
                                    className="dropdown-item"
                                    onClick={() => handleOpenModal(user)}
                                  >
                                    Edit
                                  </Link>

                                  <div className="dropdown-divider"></div>
                                  <Link
                                    className="dropdown-item text-danger delete-record"
                                    onClick={() => handleStatusOpenModal(user)}
                                  >
                                    {user.status === 1
                                      ? "Deactivate"
                                      : "Activate"}
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div
                      className="pagination d-flex justify-content-end mt-3"
                      style={{ margin: "20px", marginRight: "20px" }}
                    >
                      <button
                        className="btn btn-primary me-2"
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={handleNextPage}
                        disabled={currentPage * usersPerPage >= totalUsers}
                      >
                        Next
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Confirmation Modal */}
      <div
        className="modal fade"
        id="userModal"
        tabIndex="-1"
        aria-labelledby="userModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="userModalLabel">
                Are you sure?
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>
                Do you really want to change the status of{" "}
                {selectedUser?.username}?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleConfirmation}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Add/Edit User Modal */}
      <div
        className="modal fade"
        id="addUserModal"
        tabIndex="-1"
        aria-labelledby="addUserModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-simple">
          <div className="modal-content p-3 p-md-5">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close btn-pinned"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="text-center mb-4">
                <h3>{mode === "add" ? "Add New User" : "Edit User"}</h3>
                <p>
                  {mode === "add"
                    ? "Fill the form below to add a new user."
                    : "Update the user details below."}
                </p>
              </div>
              <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={Yup.object({
                  username: Yup.string().required("Username is required"),
                  email: Yup.string()
                    .email("Invalid email address")
                    .required("Email is required"),
                  password:
                    mode === "add"
                      ? Yup.string().required("Password is required")
                      : Yup.string(),
                  confirmPassword:
                    mode === "add"
                      ? Yup.string()
                          .oneOf(
                            [Yup.ref("password"), null],
                            "Passwords must match"
                          )
                          .required("Confirm Password is required")
                      : Yup.string(),
                  roleName: Yup.string().required("Role is required"),
                })}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  if (mode === "add") {
                    try {
                      await registerUser({
                        variables: {
                          username: values.username,
                          email: values.email,
                          password: values.password,
                          roleName: values.roleName,
                        },
                      });
                      setSuccessMessage("User registered successfully!");
                      resetForm();
                      closeModal("addUserModal");
                    } catch (error) {
                      console.error("Error registering user:", error);
                    }
                  } else if (mode === "edit") {
                    try {
                      await updateUser({
                        variables: {
                          id: selectedUser.id,
                          username: values.username,
                          email: values.email,
                          roleName: values.roleName,
                        },
                      });
                      setSuccessMessage("User updated successfully!");
                      resetForm();
                      closeModal("addUserModal");
                    } catch (error) {
                      console.error("Error updating user:", error);
                    }
                  }
                  setSubmitting(false);
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="mb-3">
                      <label htmlFor="username" className="form-label">
                        Username
                      </label>
                      <Field
                        name="username"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                      />
                      <ErrorMessage
                        name="username"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <Field
                        name="email"
                        type="email"
                        className="form-control"
                        autoComplete="off"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    {mode === "add" && (
                      <>
                        <div className="mb-3">
                          <label htmlFor="password" className="form-label">
                            Password
                          </label>
                          <Field
                            name="password"
                            type="password"
                            className="form-control"
                            autoComplete="new-password"
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="confirmPassword"
                            className="form-label"
                          >
                            Confirm Password
                          </label>
                          <Field
                            name="confirmPassword"
                            type="password"
                            className="form-control"
                            autoComplete="new-password"
                          />
                          <ErrorMessage
                            name="confirmPassword"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </>
                    )}
                    <div className="mb-3">
                      <label htmlFor="roleName" className="form-label">
                        Role Name
                      </label>
                      <Field
                        as="select"
                        name="roleName"
                        className="form-select"
                      >
                        <option value="">Select a role...</option>
                        {rolesData?.roles.map((role) => (
                          <option key={role.id} value={role.name}>
                            {role.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="roleName"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isSubmitting}
                    >
                      {mode === "add" ? "Add User" : "Update User"}
                    </button>
                    <button
                      type="reset"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Cancel
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersList;
