// src/graphql/tokenMutations.js
import { gql } from "@apollo/client";

export const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshToken($token: String!) {
    refreshToken(token: $token) {
      token
      id
      username
      email
      status
      role {
        id
        name
        permissions
      }
    }
  }
`;
